import {CarouselProvider, Slide, Slider, ButtonBack, ButtonNext} from "pure-react-carousel";
import React, {useState} from "react";
import CarouselButton from "../assets/icons/caretButtonBlack.svg";
import useResizeObserver from '@react-hook/resize-observer'

const useSize = (target) => {
  const [size, setSize] = useState()

  React.useLayoutEffect(() => {
    setSize(target.current)
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}


const CarouselImpl = ({children, visibleSlides = 1, minwidth, buttonContainerWidth, buttonBgColor, buttonTopPosDivisor}) => {
  const firstCardRef = React.useRef(null);
  const size = useSize(firstCardRef);

  const slideHeight = size ? size.height : 170.8;
  const slideWidth = size ? size.width : window.innerWidth > 768 ? slideHeight : slideHeight;

  if (children.length === 0) return null;
  const _visibleSlides = Math.max(1, visibleSlides);
  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={slideHeight/10}
      totalSlides={children.length}
      infinite={window.innerWidth < 768}
      visibleSlides={_visibleSlides}
      className="w-full flex flex-col relative z-0"
      step={1}
      dragStep={1}
    >
      <Slider classNameAnimation={'transition-transform duration-300 ease-in-out'} classNameTrayWrap="w-full" classNameTray="flex" moveThreshold={"0.5"}>
        {children.map((item, index) => (
          <Slide key={index} index={index} className="items-stretch max-w-[38rem]" style={{minWidth: minwidth}}>
            <div ref={index === 0 ? firstCardRef : null}>
              <div className="py-2 ">
                {item}
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
      {window.innerWidth > 768 ? (
        <div className="flex justify-between absolute top-8 right-0" style={{
          width: buttonContainerWidth
        }}>
          <ButtonBack
            id={"back-button"}
            className={`z-10 absolute dark:bg-black rounded-md left-0 flex items-center justify-center w-8 h-8 bg-white`}
            style={{
              top: `calc(-${slideHeight}px/${buttonTopPosDivisor})`,
              backgroundColor: buttonBgColor
              // height: "20px",
            }}>
            <img src={CarouselButton} alt="CarouselBackButton" className="w-9/12"/>
          </ButtonBack>
          <ButtonNext
            id={"next-button"}
            className={`absolute dark:bg-black rounded-md left-12 z-10 flex items-center justify-center w-8 h-8`}
            style={{
              top: `calc(-${slideHeight}px/${buttonTopPosDivisor})`,
              backgroundColor: buttonBgColor
              // height: "20px",
              // right: "0px",
              // float: "right",
            }}>
            <img src={CarouselButton} alt="CarouselNextButton" className="w-9/12 rotate-180"/>
          </ButtonNext>
        </div>
      ) : null}
    </CarouselProvider>
  );
};

export default CarouselImpl;
