import React, {useEffect, useState} from "react";
import blackEdit from "../../assets/icons/blackEdit.svg";
import CollapsibleCarousel from "./collapsibleCarousel";
import BACKEND_URL from "../../constants";
import useAxios from "../../utils/useAxios";
export default function LearningSchedule({setViewMode}) {
    const [mode, setMode] = useState(0);
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [data, setData] = useState([]);
    const axios = useAxios();
    // const data = [
    //     {
    //         heading: "Gaining Momentum",
    //         subheading: "Let's start with some introductory topics to get you going!",
    //         levels: ["Linear Equations", "Quadratic Equations", "GST", "Arithmetics Progression", "Worksheet", "Linear Equations", "Quadratic Equations"],
    //         percentageCompleted: [100, 75, 0, 0, 0, 0, 0],
    //         locked: [false, false, true, true, true, true, true]
    //     },
    //     {
    //         heading: "Algebra",
    //         subheading: "Practising Algebra - Important to understand data and its structures.",
    //         levels: ["Linear Equations", "Quadratic Equations", "GST", "Arithmetics Progression", "Worksheet", "Linear Equations", "Quadratic Equations"],
    //         percentageCompleted: [100, 75, 0, 0, 0, 0, 0],
    //         locked: [false, false, true, true, true, true, true]
    //     },
    //     {
    //         heading: "Danger Topics",
    //         subheading: "After practising so many topics, we do need to go back and polish our weak chapters",
    //         levels: ["Linear Equations", "Quadratic Equations", "GST", "Arithmetics Progression", "Worksheet", "Linear Equations", "Quadratic Equations"],
    //         percentageCompleted: [100, 75, 0, 0, 0, 0, 0],
    //         locked: [false, false, true, true, true, true, true]
    //     }
    // ];
    useEffect(() => {
        axios.get(`${BACKEND_URL}/athena_student/learning_plan`).then((response) => {
            console.log(response, "response")
            setData(response?.data?.learning_modules);
        });
    }, []);
    console.log(data, "data")
    return (
        <div className={`w-full`}>
            <div className={`flex flex-col items-center w-full`}>
                {/*<div className={`w-fit rounded-xl bg-[#F9FAFB] dark:bg-[#121212] text-base p-2 flex gap-10 font-SaB relative`}>*/}
                {/*    <div*/}
                {/*        id="slider"*/}
                {/*        className={`absolute bottom-[0.2rem] z-0 left-1.5 transition-transform duration-300 ${*/}
                {/*            mode === 1 ? "translate-x-[119%]" : ""*/}
                {/*        } w-[44%] h-[85%] bg-white dark:bg-[#222222] rounded-lg`}*/}
                {/*        style={{*/}
                {/*            boxShadow: `${!isDarkMode ? `0px 2px 6px -2px rgba(212, 212, 212, 1),*/}
                {/*            0px 0px 0px 1px rgba(234, 237, 240, 1),*/}
                {/*            0px -2px 4px 0px rgba(244, 244, 244, 1) inset` : ''}`,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <button*/}
                {/*        className={`${mode === 0 ? "text-[#2A323A] dark:text-white" : "text-[#888888] font-Sa"}  z-10 px-5 py-1 `}*/}
                {/*        onClick={() => {*/}
                {/*            setMode(0);*/}
                {/*        }}>*/}
                {/*        Learning Plan*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className={`${mode === 1 ? "text-[#2A323A] dark:text-white" : "text-[#888888] font-Sa"} z-10 pl-9 pr-10 py-1 `}*/}
                {/*        onClick={() => {*/}
                {/*            setMode(1);*/}
                {/*        }}>*/}
                {/*        Insights*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className={`flex w-full justify-between pr-16 mt-10`}>
                    <div className={`w-fit`}>
                        <p className={`font-SaB text-3xl mb-3 text-black dark:text-white`}>Unit Test Learning Plan</p>
                        <button className={`flex items-center gap-x-2.5 py-2 px-5 border border-[#D5D5D5] rounded-lg mt-5`} onClick={() => setViewMode(1)}>
                            <p className={`font-Sa text-base text-black dark:text-white`}>Add Plan</p>
                            <img src={blackEdit} alt={"edit"} className={`w-4 h-4 dark:invert`} />
                        </button>
                    </div>
                    <div className={`flex flex-col items-center bg-[#F9FAFB] dark:bg-darkBackground dark:border-[#38a4d0] border-2 rounded-2xl pt-4 pb-5 px-10`}>
                        <p className={`font-SaM text-base text-[#767676] mb-2 dark:invert`}>Days left for Exam</p>
                        <p className={`font-SaB text-[#38a4d0] text-5xl`}>29</p>
                    </div>
                </div>
                <div className={`flex flex-col w-full`}>
                    <hr className={`w-[93%] border border-[#E8E8E8] my-8 `}/>
                    <div className={`flex flex-col gap-y-7`}>
                        {
                            data?.map(({ topic, id }, index) => {
                                return(
                                    <CollapsibleCarousel key={index} topic={topic} id={id} heading={topic?.[0]?.chapter?.name}  />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
