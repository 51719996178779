import React, {useState} from "react";
import Dropdown from "../dropdown";
import StatusButton from "../insights/StatusButton";

export default function OnboardingView4({prevViewState, setPrevViewState, setViewState, selectedHourOption, setSelectedHourOption}) {
  // const [mathLevel, setMathLevel] = useState(['AA HL', 'AI HL', 'AA SL', 'AI SL']);
  // return(
  //     <div className={`w-7/12 flex flex-col justify-evenly items-center`}>
  //       <div className={`flex flex-col w-10/12 relative justify-between items-center mb-10`}>
  //         <p className={`text-xl font-SaM dark:text-white mb-5`}>Choose your Math Level...</p>
  //         <div className={`w-full flex flex-col gap-4 rounded-xl dark:bg-[#2e3c41] p-4`}>
  //           {
  //             mathLevel.map((data, index) => {
  //               return(
  //                   <button key={index} className={`bg-white dark:bg-darkBackground text-black dark:text-white text-base font-SaM w-full h-20 rounded-lg focus:bg-[#38a4d0]`}>
  //                     {data}
  //                   </button>
  //               )
  //             })
  //           }
  //         </div>
  //       </div>
  //       {/*<input type="text" className={`w-10/12 rounded-full p-4 dark:bg-[#2E3C41] dark:text-white`} placeholder="Enter your name"/>*/}
  //       <div className={`flex justify-between w-10/12`}>
  //         <button className={`w-4/12 bg-white dark:bg-[#2e3c41] text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`} onClick={()=>{
  //           setViewState(2);
  //         }}>
  //           Go Back
  //         </button>
  //         <button
  //             className={`w-4/12 bg-white dark:bg-darkgreenAccent text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`}
  //             onClick={() => {
  //               setViewState(4);
  //             }}>
  //           Continue
  //         </button>
  //       </div>
  //
  //     </div>
  // )

  const [hourOptions, setHourOptions] = useState(['Less than 1 hour', 'Between 1-4 hours', 'More than 4 hours']);
  return(
      <div className={`w-7/12 flex flex-col justify-evenly items-center`}>
        <div className={`flex flex-col w-10/12 relative justify-between items-center mb-10`}>
          <p className={`text-xl font-SaM dark:text-white mb-5 text-center w-full`}>How many hours per week do you want to commit to improving your grades?</p>
          <div className={`w-full flex flex-col gap-4 rounded-xl dark:bg-[#2e3c41] p-4`}>
            {
              hourOptions.map((hourOption, index) => {
                return(
                    <button className={`text-black dark:text-white text-base font-SaM w-full h-20 rounded-lg`} onClick={()=>{
                      setSelectedHourOption(hourOption);
                    }} style={{
                      backgroundColor: selectedHourOption === hourOption ? "#38a4d0" : "#1c2226",
                    }} key={index} >
                      {hourOption}
                    </button>
                )
              })
            }
          </div>
        </div>
        {/*<input type="text" className={`w-10/12 rounded-full p-4 dark:bg-[#2E3C41] dark:text-white`} placeholder="Enter your name"/>*/}
        <div className={`flex justify-between w-10/12`}>
          <button className={`w-4/12 bg-white dark:bg-[#2e3c41] text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`} onClick={()=>{
            setViewState(prevViewState);
          }}>
            Go Back
          </button>
          <button
              className={`w-4/12 bg-white disabled:opacity-50 dark:bg-darkgreenAccent text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`}
              onClick={() => {
                setViewState(4);
              }}
              disabled={!selectedHourOption}
          >
            Continue
          </button>
        </div>

      </div>
  )
}