import React from "react";
import roadmapIndicator from "../../assets/icons/roadmapIndicator.svg";
import recommendedChaptersButtonBg from "../../assets/icons/recommendedChaptersButtonBg.svg"
import recommendedChaptersButtonBgDark from "../../assets/icons/recommendedChaptersButtonBgDark.svg"
import roadmapLockedIndicatior from "../../assets/icons/roadmapLockedIndicatior.svg"
import ProgressRing from "../ProgressRing";
import chapterBoxButton from "../../assets/icons/chapterBoxButton.svg";
function getColorByPercentage(color, percent){
  let R1 = parseInt(color.substring(1, 3), 16);
  let G1 = parseInt(color.substring(3, 5), 16);
  let B1 = parseInt(color.substring(5, 7), 16);
  let R2;
  let G2;
  let B2;
  if(percent>0){
    R2 = Math.round(R1 + (255 - R1) * ((100 - percent) / 100));
    G2 = Math.round(G1 + (255 - G1) * ((100 - percent) / 100));
    B2 = Math.round(B1 + (255 - B1) * ((100 - percent) / 100));
  }
  // console.log(R2, G2, B2, "R, G, B")

  R2 = (R2 < 255) ? R2 : 255;
  G2 = (G2 < 255) ? G2 : 255;
  B2 = (B2 < 255) ? B2 : 255;

  const RR = R2.toString(16).padStart(2, '0');
  const GG = G2.toString(16).padStart(2, '0');
  const BB = B2.toString(16).padStart(2, '0');
  return `#${RR}${GG}${BB}`;
}

export default function Step({topic_id, number, name, percentageCompleted, currentTopic, setCurrentTopic}){
  const color = '#0f9d76';
  const finalCircleFillColor = getColorByPercentage(color, percentageCompleted)
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  return (
      <button className={`flex relative  w-full ${(number+1) % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
        onClick={() => {
          setCurrentTopic(number);
        }}
      >
        <div className={`flex relative bg-white dark:bg-darkBackground w-5/12 flex-col ${(number+1) % 2 === 0 ? 'items-end mr-8' : ' items-start ml-8'}`}>
          <p className={`font-SaM text-sm text-[#333333] dark:text-[#FCFCFC99] text-opacity-60 mb-1 ${(number+1) % 2 === 0 ? 'text-right' : 'text-left'}`}>Topic {topic_id}</p>
          <p className={`font-SaM w-11/12 text-base text-[#4A4A4A] dark:text-white mb-1 ${(number+1) % 2 === 0 ? 'text-right' : 'text-left'}`}>{name}</p>
          <p className={`font-SaM text-base uppercase mb-1 ${percentageCompleted===100 ? 'text-[#50929C]' : `${percentageCompleted > 0 ? 'text-[#94C2CA]' : 'text-[#ADB7B9]'}`} ${(number+1) % 2 === 0 ? 'text-right' : 'text-left'}`}>{percentageCompleted===100 ? 'completed' : `${percentageCompleted > 0 ? 'in progress' : 'to be started'}`}</p>
          <div
              className={`w-fit rounded-full absolute ${(number+1) % 2 === 0 ? 'right-[-42%]' : 'right-[98%]'}`}
          >
            <svg width="50" height="50">
              <circle cx="10" cy="10" r="10" fill={percentageCompleted > 0 ? finalCircleFillColor : '#ADB7B9'}/>
            </svg>
          </div>
        </div>
        <div key={number}
             className={`flex flex-col relative ${(number+1) % 2 === 0 ? 'ml-10' : 'mr-10'} w-36 h-36 items py-6 px-6 min-h-fit rounded-[2.125rem]`}
          style={{
            backgroundColor: `${percentageCompleted > 0 ? `rgb(46 60 65 / ${percentageCompleted/100})` : `#ADB7B9`}`,
          }}
        >
          <div className={`absolute z-0 -bottom-5 -right-5 w-[6.5rem] text-lg rounded-lg`}>
            <img src={isDarkMode ? recommendedChaptersButtonBgDark : recommendedChaptersButtonBg} className={`w-11/12`} alt={'exploreBg'}/>
          </div>
          <button style={{ '--progress-percentage': `${percentageCompleted}%`, backgroundColor: getColorByPercentage(color, percentageCompleted) }} className={`progress-circle flex justify-center items-center  absolute z-10 -bottom-1 right-1 w-16 h-16 rounded-full text-lg`}>
            <ProgressRing radius={38.5} stroke={4.5} progress={percentageCompleted}/>
            <img src={percentageCompleted > 0 ? chapterBoxButton : roadmapLockedIndicatior} style={{
              opacity: `${percentageCompleted > 0 ? `${percentageCompleted/100}` : '1'}`,
            }} className={`w-8/12 ${(percentageCompleted > 0) ? (((((number+1) % 2) === 0)) ? '-rotate-90' : 'rotate-180') : 'rotate-0' } invert`} alt={'explore'}/>
          </button>
          {/*<CustomCircle/>*/}
        </div>
      </button>
  )
}