import React from "react";
import PracticeChapterItems from "./practiceChapterItems";
import greenSlider from "../../../assets/icons/greenSlider.svg";
import graySlider from "../../../assets/icons/graySlider.svg";
import whiteSlider from "../../../assets/icons/whiteSlider.svg";

export default function ContinuePractice({ chapterList }) {

    const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    console.log(chapterList, "chapterList")
    return (
        <div className={`flex flex-col mt-10`}>
            <p className={`font-SaM text-2xl text-black dark:text-white mb-4`}>Continue Practising</p>
            <div className={`gap-4 grid grid-cols-3`}>
                {chapterList?.slice(0,3).map(({ topic, sub_topic, id }, index) => (
                    <PracticeChapterItems
                        topic_id={topic?.id}
                        topic={topic?.topic}
                        subtopic={sub_topic}
                        key={index}
                        sliderImg={index%3===0 ?  whiteSlider : index%3===1 ? greenSlider : graySlider}
                        bgColor={
                            index % 3 === 0
                                ? `${isDarkMode ? "#0f9d76" : "#60AFBB"}`
                                : index % 3 === 1
                                ? `${isDarkMode ? "#2E3C41" : "#398090"}`
                                : `${isDarkMode ? "white" : "#3E6673"}`
                        }
                    />
                ))}
            </div>
        </div>
    );
}
