import React from "react";
import menuDots from "../../../assets/icons/menuDots.svg";
import timeIndicator from "../../../assets/icons/timeIndicator.svg";



export default function TaskItem({topic, num, duration, borderColor}) {
  return(
      <div className={`flex ${num===0 ? 'dark:bg-[#0f9d76]' : 'dark:bg-darkBackground'} rounded-3xl py-4 px-5 gap-4 w-full min-w-fit flex-wrap items-center justify-between border-l-0`}
        // style={{
        //   borderColor: `${borderColor}`
        // }}
      >
        {/*<div className={`flex flex-col ml-5 py-4`}>*/}
          <p className={`text-[#415180] dark:text-white font-SaM text-lg`}>{topic}</p>
        {/*</div>*/}
        {/*<div className={`flex flex-col justify-between items-end pr-2`}>*/}
          {/*<button className={`w-5 mb-5`}>*/}
          {/*  <img src={menuDots} alt="menuDots"/>*/}
          {/*</button>*/}
            {/*<img src={timeIndicator} alt="timeIndicator"/>*/}
            <p className={`text-xl min-w-fit w-fit  font-Nasa ml-2 ${num===0 ? 'dark:text-darkBackground' : 'dark:text-[#0f9d76]'}`}>{duration}</p>
        {/*</div>*/}
      </div>
  )
}