import React, { useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import smiley from "../../assets/icons/insights/smiley.svg";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import "tailwindcss/tailwind.css";
import { callback } from "chart.js/helpers";
import GaugeChart from 'react-gauge-chart';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title);

export default function AccuracyAndScore() {
    // const [data, setData] = useState([]);
    // const [score, setScore] = useState(0);
    // const [increase, setIncrease] = useState(0);

    // useEffect(() => {
    //   // Fetch data from your API
    //   axios.get('https://api.example.com/weekly-data')
    //     .then(response => {
    //       setData(response.data);
    //     })
    //     .catch(error => {
    //       console.error('Error fetching the data:', error);
    //     });
    // }, []);
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const data = [
        { chapter: "Functions", percentage: 80 },
        { chapter: "AP", percentage: 40 },
        { chapter: "Mensuration", percentage: 70 },
        { chapter: "Probability", percentage: 20 },
        { chapter: "Cylinders", percentage: 50 },
        { chapter: "Equations", percentage: 90 },
        { chapter: "Circles", percentage: 10 },
    ];
    const score = 45;
    const increase = 30;
    const getGradient = (ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, "rgba(43, 175, 194, 0.4)");
        gradient.addColorStop(1, "rgba(27, 127, 142, 1)");
        return gradient;
    };

    const chartData = {
        labels: data.map((item) => item.chapter),
        datasets: [
            {
                label: "",
                data: data.map((item) => item.percentage),
                // backgroundColor: "linear-gradient(180deg, #1B7F8E 30%, #2BAFC2 100%)",
                backgroundColor: function (context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return null;
                    }
                    return getGradient(ctx, chartArea);
                },
                borderRadius: 10,
                barPercentage: 0.9, // Adjusts the thickness of the bars (optional)
                categoryPercentage: 0.5, // Reduces the category size to increase spacing
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true, // Ensure tooltips are enabled for clarity
                bodyFont: {
                    size: 14, // Increase tooltip font size for readability
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        family: "Satoshi",
                        size: 14, // Increase font size for x-axis labels
                    },
                    color: isDarkMode ? "#FFFFFF" : "#000", // High contrast color for x-axis labels
                },
                grid: {
                    color: isDarkMode ? "#FFFFFF" : "rgba(0,0,0,0.1)", // Lighter grid lines
                    display: false, // Hide x-axis grid lines
                },
            },
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    font: {
                        family: "Satoshi-Medium",
                        size: 14, // Increase font size for y-axis labels
                    },
                    color: isDarkMode ? "#FFFFFF" : "rgba(0,0,0,0.1)", // Lighter grid lines
                    callback: function (value, index, values) {
                        return value + "%"; // Append percentage symbol to y-axis labels
                    },
                },
                title: {
                    display: true,
                    text: "Percentage Correct %",
                    color: isDarkMode ? "#FFFFFF" : "#000", // Lighter grid lines
                    font: {
                        family: "Satoshi-Bold",
                        size: 14, // Increase font size for y-axis labels
                    },
                },
                grid: {
                    color: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)", // Lighter grid lines
                },
            },
        },
        animation: {
            duration: 1000, // Smooth 1 second animation for drawing bars
        },
        // maintainAspectRatio: false, // Allows custom sizing
        responsive: true, // Ensures the chart is responsive to window size
    };

    return (
        <div className={`w-full flex gap-8 mb-20`}>
            <div className=" w-[70%] relative p-5 bg-white dark:bg-[#2e3c41] rounded-3xl shadow-md">
                <div className="flex justify-center items-center mb-4">
                    <h2 className="text-xl text-[#3F3F3F] mb-5 font-SaB text-center dark:text-white">Chapter Accuracy</h2>
                </div>
                <p className="absolute right-4 top-4 text-black text-sm font-SaM  dark:text-white">
                    <span className={`text-[#FF955A]`}>+30%</span> this week
                </p>
                <Bar data={chartData} options={options}/>
                <div className="mt-2 p-4 inline-flex w-full items-center justify-center ">
                    <img src={smiley} alt="smiley" className="h-6 w-6 mr-2"/>
                    <p className="text-[#767676] font-SaB text-sm dark:text-white">Focus on Mensuration to bring up your predictive
                        score</p>
                </div>
            </div>

            {/*<div className="inline-flex flex-col justify-between bg-white dark:bg-[#222222] rounded-3xl shadow-md">*/}
            {/*    <h2 className="text-xl pt-6 font-SaB text-center dark:text-white">Predicted Score</h2>*/}
            {/*    <GaugeChart*/}
            {/*        id="gauge-chart"*/}
            {/*        className=" w-full"*/}
            {/*        nrOfLevels={25}*/}
            {/*        arcPadding={0.0}*/}
            {/*        cornerRadius={10}*/}
            {/*        // marginInPercent={0.05}*/}
            {/*        percent={score / 100}*/}
            {/*        colors={['#166E7B', '#3C8B99', '#5AA1AD', '#77B1BC']}*/}
            {/*        arcWidth={0.2}*/}
            {/*        arcsLength={[0.25, 0.25, 0.25, 0.25]}*/}
            {/*        needleColor="rgba(27, 127, 142, 0.4)"*/}
            {/*        needleBaseColor="rgba(17, 93, 104, 1)"*/}
            {/*        hideText*/}
            {/*        textColor={`${isDarkMode ? "#FFFFFF" : "#000"}`}*/}
            {/*    />*/}
            {/*    <div className={`text-center pb-6`}>*/}
            {/*        <div className="text-6xl font-Sa text-center mb-4 dark:text-white">{score}</div>*/}
            {/*        <div className={`text-[#115D68] text-lg font-SaB text-center`}>{increase}% <span className={`font-Sa text-[#115D68]`}>increase </span><span className={`font-SaB text-greenAccent`}>↑</span></div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
}
