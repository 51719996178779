import React, {useEffect, useRef, useState} from "react";
import exploreButton from "../../assets/icons/chapterBoxButton.svg";
import ProgressRing from "../ProgressRing";
import {useNavigate} from "react-router-dom";
import tick from "../../assets/icons/tick.svg";
export default function SelectChapterItems({key, bgColor, buttonBgColor, mainTopic, subTopic, topic_id, topicIds, setTopicIds, setGenerateWorksheetTopics}) {
  const exploreButtonRef = useRef(null);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const handleHoverEnter = () => {
    exploreButtonRef.current.classList.add("scale-110");
    exploreButtonRef.current.classList.remove("scale-100");
  }
  const handleHoverLeave = () => {
    exploreButtonRef.current.classList.add("scale-100");
    exploreButtonRef.current.classList.remove("scale-110");
  }
  const handleClick = () => {
    if (!checked) {
    setTopicIds((prev) => {
      if (!prev.includes(topic_id)) {
        return [...prev, topic_id];
      }
      return prev;
    });

    setGenerateWorksheetTopics((prev) => {
      if (!prev.includes(subTopic)) {
        return [...prev, {id: topic_id, name: subTopic}];
      }
      return prev;
    });

  } else {
    setTopicIds((prev) => prev.filter((id) => id !== topic_id));
    setGenerateWorksheetTopics((prev) => prev.filter((topic) => topic.id !== topic_id));
  }
    console.log("clicked");
  }

  useEffect(() => {
    if (topicIds.includes(topic_id)) {
      setChecked(true);
    }else{
      setChecked(false);
    }
  }, [topicIds, topic_id]);
  return (
      <div
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
          key={key}
          className={`flex flex-col relative cursor-pointer w-52 h-52 py-6 px-6 min-h-fit bg-cover bg-no-repeat hover:scale-110 hover:translate-x-[5%] hover:translate-y-[5%] transition-transform rounded-3xl rounded-br-[3rem]`}
          onClick={() => {
            handleClick();
          }}
          style={{
            backgroundColor: bgColor,
          }}
      >
        <div className={`flex flex-col`}>
          <p className={`font-SaM text-[#33333399] dark:text-white dark:text-opacity-60 text-sm leading-5 mb-1`}>{mainTopic}</p>
          <p className={`font-SaM text-[#4A4A4A] dark:text-white text-lg`}>{subTopic}</p>
        </div>
        <button
            className={`absolute -bottom-5 -right-4 font-Sa w-24 h-24 text-lg rounded-full`}
            style={{
              backgroundColor: buttonBgColor
            }}
        >
        </button>

        <button ref={exploreButtonRef} className={`absolute ${checked ? 'bg-greenAccent dark:bg-darkgreenAccent' : 'bg-transparent '} border-2 border-greenAccent dark:border-[#0f9d76] p-3 rounded-full -bottom-1 right-0 w-[4.2rem] h-[4.2rem] text-lg transition-all ease-in-out`}>
          {checked && <img src={tick} className={`w-full`} alt={'explore'}/>}
        </button>
      </div>
  )
}