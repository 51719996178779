import { useAuthStore } from "../store/auth";
import axios from "../utils/axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export const login = async (email, password) => {
    try {
        const { data, status } = await axios.post("authenticate/login/", {
            email: email,
            password: password
        });

        if (status === 200) {
            setAuthUser(data.access_token, data.refresh_token);
            return { data, error: null };
        } else {
            return { data: null, error: "Invalid email or password. Please try again." };
        }
    } catch (error) {
        return {
            data: null,
            error: error?.response?.data || "An unexpected error occurred. Please try again later.",
        };
    }
};

export const signup = async (
    name,
    email,
    school,
    gender,
    grade,
    password,
    selectedCurriculum,
    selectedMathLevel,
    selectedHourOption,
    selectedChapters
) => {
    try {
        const { data, status } = await axios.post("authenticate/signup/", {
            name: name,
            email: email,
            school: school,
            gender: gender,
            grade: grade,
            password: password,
            board: selectedCurriculum,
            math_level: selectedMathLevel,
            hours: selectedHourOption,
            chapters: selectedChapters
        })
        console.log(data, "data", status, "status")
        if (status === 200) {
            return { data, error: null };
        }else{
            return { data: null, error: "Something went wrong" };
        }
    } catch (error) {
        return {
            data: null,
            error: error?.response?.data || "Something went wrong",
        };
    }
};

// export const sendOTP = async (email) => {
//     try {
//         const response = await axios.post("send_otp/", {
//             email,
//         });
//         return { data: response?.data, status: response?.status, error: null };
//     } catch (error) {
//         return {
//             data: null,
//             status: error?.response?.status,
//             error: error?.response?.data?.message || "Something went wrong",
//         };
//     }
// };
//
// export const verifyOTP = async (otp, email) => {
//     try {
//         const { data, status } = await axios.post("verify_otp/", {
//             email,
//             otp,
//         });
//         return { data: data, status: status, error: null };
//     } catch (error) {
//         return {
//             data: null,
//             status: error.response.status,
//             error: error.response.data.message || "Something went wrong",
//         };
//     }
// };

export const logout = async () => {
    const {data, status} = await axios.post("authenticate/logout/", {
        access_token: Cookies.get("access_token"),
    });
    if(status === 200){
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        useAuthStore.getState().setUser(null);
        useAuthStore.getState().setLoading(false);
        return { data, error: null };
    }else{
        return { data: null, error: "Something went wrong" };
    }
};

export const setUser = async () => {
    // ON PAGE LOAD
    const accessToken = Cookies.get("access_token");
    const refreshToken = Cookies.get("refresh_token");
    console.log(accessToken, refreshToken, "access token", "refresh token");
    if (!accessToken || !refreshToken) {
        console.log("no access token or refresh token");
        return false;
    }
    if (isAccessTokenExpired(accessToken)) {
        const response = await getRefreshToken(refreshToken);
        setAuthUser(response.access, response.refresh);
    } else {
        setAuthUser(accessToken, refreshToken);
    }
    return true;
};

export const setAuthUser = (access_token, refresh_token) => {
    const expiresInHours = 3;
    const expiresInDays = expiresInHours / 24;
    Cookies.set("access_token", access_token, {
        expires: expiresInDays,
        secure: false,
    });

    Cookies.set("refresh_token", refresh_token, {
        expires: 7,
        secure: false,
    });

    const user = jwtDecode(access_token) ?? null;

    if (user) {
        useAuthStore.getState().setUser(user);
    }
    useAuthStore.getState().setLoading(false);
};

export const getRefreshToken = async () => {
    const refresh_token = Cookies.get("refresh_token");
    const response = await axios.post("authenticate/token/refresh/", {
        refresh: refresh_token,
    });
    return response.data;
};

export const isAccessTokenExpired = (accessToken) => {
    try {
        const decodedToken = jwtDecode(accessToken);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true; // Token is invalid or expired
    }
};

export const resetPassword = async (email) => {
    try {
        const { data, status } = await axios.post("reset_password/", {
            email,
        });
        return { data: "Email Send Successfully", status: status, error: null };
    } catch (error) {
        return {
            data: null,
            error: error.response.data || "Something went wrong",
        };
    }
};

export const changePassword = async (password, token, uidb64) => {
    try {
        const { data, status } = await axios.post("change_password/", {
            password,
            token,
            uidb64,
        });
        return { data: data, status: status, error: null };
    } catch (error) {
        return {
            data: null,
            error: error.response.data || "Something went wrong",
        };
    }
};
