import {useEffect, useState} from 'react';
import {setUser} from '../utils/auth';
import {useLocation, useNavigate} from "react-router-dom";

const Main_wrapper = ({children}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handler = async () => {
      setLoading(true);
      const isAuthenticated = await setUser();
      setLoading(false);
      if (!isAuthenticated && location.pathname !== '/onboarding' && location.pathname !== '/login') {
        navigate('/login');
      }
    };
    handler().then(r => {
      console.log(r, "loading");
    });
  }, [navigate, location.pathname]);

  return <>{loading ? null : children}</>;
};

export default Main_wrapper;