import axios from 'axios';
import { getRefreshToken, isAccessTokenExpired, setAuthUser } from './auth';
import BACKEND_URL from '../constants';
import Cookies from 'js-cookie';
import {useNavigate} from "react-router-dom";

const useAxios = () => {
    const navigate = useNavigate();
    const accessToken = Cookies.get('access_token');
    const refreshToken = Cookies.get('refresh_token');
    console.log(accessToken, refreshToken, "accessToken, refreshToken");
    const axiosInstance = axios.create({
        baseURL: BACKEND_URL,
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    axiosInstance.interceptors.request.use(async (req) => {
        if (!isAccessTokenExpired(accessToken)) return req;

        const response = await getRefreshToken(refreshToken);

        setAuthUser(response.access, response.refresh);

        req.headers.Authorization = `Bearer ${response.data.access}`;
        return req;
    });

    return axiosInstance;
};

export default useAxios;