import React, {useEffect, useRef, useState} from "react";
import Navbar from "../components/Navbar/navbar";
import ChapterTopic from "../components/chapterDetail/chapterTopic";
import Roadmap from "../components/chapterDetail/roadmap";
import { useParams } from "react-router-dom";
import BACKEND_URL from "../constants";
import useAxios from "../utils/useAxios";

export default function ChapterDetail() {
    const [currentTopic, setCurrentTopic] = useState(null);
    const [chapterDetails, setChapterDetails] = useState(null);
    const id = useParams().id;
    const axios = useAxios();

    useEffect(() => {
        axios.get(`${BACKEND_URL}/athena_student/topic/${id}/`).then((response) => {
            setChapterDetails(response.data)
        });
        setCurrentTopic(0);
    }, [id]);
    console.log(currentTopic, "currentTopic")
    console.log(chapterDetails?.[currentTopic], "chapterDetails")
    return (
        <div className={`relative w-full flex select-none dark:bg-darkBackground min-h-screen`}>
            <div className={`w-[24%] relative dark:bg-darkBackground min-h-[200vh]`}>
              <div className={`w-[21rem] fixed min-h-screen`}>
                <Navbar width={100}/>
              </div>
            </div>
            <div className={`w-[50%] min-h-screen py-20 bg-white dark:bg-darkBackground`}>
                <div className={`w-full px-10 min-h-screen fixed dark:bg-darkBackground`}>
                    <p className={`font-SaB text-4xl mb-10 dark:text-white`}>{chapterDetails?.[0]?.topic?.topic}</p>
                    <ChapterTopic topic={chapterDetails?.[currentTopic]} number={chapterDetails?.[currentTopic]?.id} />
                </div>
            </div>

            <Roadmap topics={chapterDetails} currentTopic={currentTopic} setCurrentTopic={setCurrentTopic} />
        </div>
    );
}
