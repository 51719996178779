import axios from "axios";
import BACKEND_URL from "../constants";
const apiInstance = axios.create({
    baseURL: BACKEND_URL,
    timeout: 10000, // timeout after 5 seconds
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export default apiInstance;
