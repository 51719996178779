import React, { useState } from "react";
import caretButton from "../assets/icons/caretButtonBlack.svg";
import blackCross from "../assets/icons/blackCross.svg";
import { useParams } from "react-router-dom";
import Latex from "react-latex-next";
import magicWandGrey from "../assets/icons/evaluateWorksheet/magicWandGrey.svg";
import magicWandWhite from "../assets/icons/worksheet/magicWandWhite.svg";
import partyPopper from "../assets/icons/evaluateWorksheet/partyPopper.svg";
import rephrase from "../assets/icons/evaluateWorksheet/rephrase.svg";
export default function EvalWorksheet() {
    const id = useParams().id;
    console.log(id, "id");
    const [progress, setProgress] = useState(10);
    const question = "Find the value of ∫2x cos (x² - 5)";
    const [mode, setMode] = useState(0);
    const [optionSelected, setOptionSelected] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    return (
        <div className="min-h-screen bg-gray-50 dark:bg-black flex flex-col items-center  px-48 pt-12">
            {/* Header */}
            <div className="w-full flex justify-between items-center mb-8">
                <div className="flex items-center space-x-2">
                    <button className="w-fit flex items-center">
                        <img src={blackCross} className={`w-12 h-12 dark:invert`} />
                        <p className="text-xl font-SaM dark:text-white">Exit</p>
                    </button>
                </div>
                <div className="text-center">
                    <h1 className="text-4xl mb-1.5 font-SaM dark:text-white">Worksheet 4</h1>
                    <p className="text-lg text-[#757575] font-Sa dark:text-white">Arithmetic Progression</p>
                </div>
                <p className="text-xl font-Sa dark:text-white">20 XP</p>
            </div>

            {/* Progress Bar */}
            <div className="w-full bg-[#E5E5E5] h-4 rounded-full mb-6">
                <div
                    className="bg-greenAccent h-full rounded-full"
                    style={{
                        width: `${progress}%`,
                    }}></div>
            </div>

            {/* Worksheet Title */}

            {/* Question Section */}
            <div className="w-full mb-8">
                {/*<div className="flex justify-between items-center mb-4">*/}
                <button className="flex items-center space-x-1 mb-8  text-black">
                    <img src={caretButton} className={`w-7 h-7 dark:invert`} alt={"caretButton"} />
                    <p className="text-lg font-Sa dark:text-white">Previous Question</p>
                </button>
                {/*<div className="flex items-center space-x-1">*/}
                {/*  <p className="text-lg">Personalise</p>*/}
                {/*  <label className="switch">*/}
                {/*    <input type="checkbox"/>*/}
                {/*    <span className="slider round"></span>*/}
                {/*  </label>*/}
                {/*</div>*/}
                {/*</div>*/}
                <div className={`w-full px-16 text-xl h-36 bg-[#F9FAFB] dark:bg-[#222222] flex flex-col rounded-3xl justify-center relative`}>
                    <p className={`dark:text-white`}>
                        <Latex>{question}</Latex>
                    </p>
                    <div className={`flex absolute right-8 bottom-5 justify-center items-center`}>
                        <p className={`text-base mr-2 font-SaM dark:text-white`}>Personalise</p>
                        <div
                            className={`w-fit ${
                                mode === 0 ? "bg-[#D9D9D9]" : "bg-[#60AFBB] dark:bg-darkgreenAccent bg-opacity-25"
                            } rounded-full text-base py-2 flex justify-center font-SaB relative`}>
                            <div
                                className={`absolute -top-[0.1rem] z-0 left-0 w-9 h-9 transition-transform duration-300 ${
                                    mode === 1 ? "translate-x-[95%]" : ""
                                }  bg-greenAccent rounded-full`}></div>
                            <button
                                className={`z-10 px-[1.1rem] py-2 w-fit`}
                                onClick={() => {
                                    setMode(0);
                                }}
                            />
                            <button
                                className={`z-10 px-[1.1rem] py-2 w-fit`}
                                onClick={() => {
                                    setMode(1);
                                }}
                            />
                            <img
                                src={mode === 0 ? magicWandGrey : magicWandWhite}
                                className={`w-fit h-7 absolute bottom-0.5 right-0.5`}
                                alt={"toggle"}
                            />
                        </div>
                        {/*<div className={`z-0 left-1 w-1/2 transition-transform duration-300 ${selected === 1 ? 'translate-x-[93%]' : ''} h-[85%] bg-white rounded-lg`}></div>*/}
                    </div>
                </div>
                <div className={`flex items-center justify-end mt-4 mr-8`}>
                    <button>
                        <img src={rephrase} className={`w-5 h-5 mr-4 dark:invert`} alt={"rephrase"} />
                    </button>
                    <p className={`font-Sa text-sm text-black dark:text-white`}>Rephrase</p>
                </div>
                {/*<p className="text-lg"></p>*/}
            </div>

            {/* Answer Options */}
            <div className={`w-full pt-12 ${submitted ? 'pb-0' : 'pb-7'} mb-8 flex flex-col items-center border-[#EBEBEB] dark:border-[#EBEBEB40] border rounded-3xl`}>
                <div className="w-6/12 grid px-8 grid-cols-2 gap-4 mb-20">
                    <button
                        className={`${
                            optionSelected === 0 ? "bg-[#1B7F8E] dark:bg-darkgreenAccent bg-opacity-10 border-greenAccent" : "bg-[#F9FAFB] dark:bg-[#222222] border-[#EBEBEB] dark:border-[#EBEBEB40]"
                        } border rounded-lg p-4 text-lg dark:text-white`}
                        onClick={() => {
                            setOptionSelected(0);
                        }}>
                        49
                    </button>
                    <button
                        className={`${
                            optionSelected === 1 ? "bg-[#1B7F8E] dark:bg-darkgreenAccent bg-opacity-10 border-greenAccent" : "bg-[#F9FAFB] dark:bg-[#222222] border-[#EBEBEB] dark:border-[#EBEBEB40]"
                        } border rounded-lg p-4 text-lg dark:text-white`}
                        onClick={() => {
                            setOptionSelected(1);
                        }}>
                        49
                    </button>
                    <button
                        className={`${
                            optionSelected === 2 ? "bg-[#1B7F8E] dark:bg-darkgreenAccent bg-opacity-10 border-greenAccent" : "bg-[#F9FAFB] dark:bg-[#222222] border-[#EBEBEB] dark:border-[#EBEBEB40]"
                        } border rounded-lg p-4 text-lg dark:text-white`}
                        onClick={() => {
                            setOptionSelected(2);
                        }}>
                        49
                    </button>
                    <button
                        className={`${
                            optionSelected === 3 ? "bg-[#1B7F8E] dark:bg-darkgreenAccent bg-opacity-10 border-greenAccent" : "bg-[#F9FAFB] dark:bg-[#222222] border-[#EBEBEB] dark:border-[#EBEBEB40]"
                        } border rounded-lg p-4 text-lg dark:text-white`}
                        onClick={() => {
                            setOptionSelected(3);
                        }}>
                        49
                    </button>
                </div>
                <div className="flex w-full px-8 justify-center relative">
                    <button className="absolute left-10 bg-white dark:bg-black rounded-lg px-4 py-2.5 text-lg dark:text-white border-greenAccent dark:border-darkgreenAccent border" hidden={submitted}>
                        Hint ?
                    </button>
                    <div className="flex space-x-6">
                        <button
                            className={` bg-greenAccent dark:bg-darkgreenAccent text-white disabled:opacity-35 rounded-2xl px-7 py-2.5 text-xl`}
                            disabled={optionSelected === null}
                            hidden={submitted}
                            onClick={() => {
                                setSubmitted(true);
                            }}>
                            Submit
                        </button>
                        <button className="border border-greenAccent dark:border-darkgreenAccent text-black dark:text-white rounded-2xl px-7 py-2.5 text-xl" hidden={submitted}>
                            Skip
                        </button>
                    </div>
                </div>
                {submitted && (
                    <div className={`w-full flex justify-between bg-[#06BB86] dark:bg-[#06bb85a5] bg-opacity-30 px-14 py-5 rounded-b-3xl animate-slideInFromBottom`}>
                        <p className={`font-SaB text-xl dark:text-white`}>+15 XP</p>
                        <div className={`flex gap-4 items-center`}>
                            <img src={partyPopper} className={`w-9 h-9`} />
                            <p className={`font-SaB text-xl dark:text-white`}>Correct!</p>
                        </div>
                        <button className={`bg-white dark:bg-[#222222] text-black rounded-xl px-4 py-1 text-base  dark:text-white`}>See Results</button>
                    </div>
                )}
            </div>
        </div>
    );
}
