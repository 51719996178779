import React from "react";
import SearchBar from "../searchBar";
import ChapterButton from "./chapterButton";


export default function Header({chapterSuggestions}){
  console.log(chapterSuggestions, "chapterSuggestions")
  return(
      <div className={`flex flex-col w-full mt-5 mb-16`}>
        <div className={`flex gap-4 w-1/2`}>
          <p className={`font-SaB text-3xl dark:text-white mr-4`}>Chapters</p>
        </div>
        <div className={`flex w-11/12 items-center mt-10`}>
          <p className={`font-Sa text-base text-black dark:text-white mr-4`}>Scroll To:</p>
          <div className={`w-auto gap-3 flex items-center flex-wrap`}>
            {
              chapterSuggestions?.map(({chapter_id, chapter_name}, id) => {
                return(
                    <ChapterButton chapter_id={chapter_id} chapter_name={chapter_name} key={id} className={'dark:border-darkgreenAccent'}/>
                )
              })
            }
          </div>
        </div>
      </div>
  )
}