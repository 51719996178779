import React from 'react';
// import './ProgressRing.css'; // Import custom CSS if needed
import exploreButton from "../assets/icons/exploreButton.svg"
const ProgressRing = ({ radius, stroke, progress, strokeColor }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
      <svg
          height={radius * 2}
          width={radius * 2}
          className="progress-ring"
      >
        <circle
            className="progress-ring__background"
            stroke="transparent"
            strokeWidth={stroke}
            fill="transparent"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
        />
        <circle
            className="progress-ring__circle"
            stroke={strokeColor || "#0f9d76"}
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{strokeDashoffset}}
            strokeLinecap="round"
            fill="transparent"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
        />

      </svg>

  );
};

export default ProgressRing;