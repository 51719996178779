import React from "react";


export default function ChapterButton({chapter_id, chapter_name, className = ""}) {
  const handleClick = () => {
    const element = document.getElementById(`chapter-${chapter_id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return(
    <button
      className={`inline-flex items-center rounded-full bg-white dark:bg-darkBackground border-greenAccent border-2 text-black dark:text-white py-1 px-3 text-sm font-Sa ${className}`}
      onClick={() => {
        handleClick();
      }}
    >
      {chapter_name}
    </button>
  )
}