import React, {useState} from "react";
import caretButtonBlack from "../../assets/icons/caretButtonBlack.svg";
import ExploreChapterItems from "../homepage/Explore/exploreChapterItems";



export default function ChapterRecommendation({chapter_id, chapter_name, topics }){
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [expanded, setExpanded] = useState(false);
  return (
      <div id={`chapter-${chapter_id}`} className={`flex flex-col pt-16`}>
        <div className={`flex justify-between w-11/12`}>
          <p className={`text-2xl font-SaM mb-5 dark:text-white`}>{chapter_name}</p>
          <button type="button"
                  className={`cursor-pointer inline-flex items-center rounded-full text-base font-SaM text-gray-900 dark:text-white`}
                  onClick={() => setExpanded(!expanded)}
          >
            <p className={`font-SaM `}>{expanded ? 'See Less' : 'View All'}</p>
            <img className={`mx-1 w-6 h-6 rotate-180 dark:invert`} src={caretButtonBlack} alt="icon"/>
          </button>
        </div>
        {expanded && (
            <div className={`grid grid-cols-4 gap-10 w-11/12`}>
              {
                topics?.map(({topic, chapter, id}, key) => {
                  return (
                      <ExploreChapterItems key={key} bgColor={isDarkMode ? '#2E3C41' : '#F3F4F6'}
                                           buttonBgColor={isDarkMode ? '#1c2226' : '#FFFFFF'}
                                           mainTopic={chapter.name} subTopic={topic} nav_link={`/topic/${id}`}/>
                  )
                })
              }
            </div>
        )}
        {!expanded && (

            <div className={`grid grid-cols-4 gap-10 w-11/12`}>
              {
                topics?.slice(0, 4).map(({topic, chapter, id}, key) => {
                  {console.log(topic, chapter, id, "topic, chapter, id")}
                  return (
                      <ExploreChapterItems key={key} bgColor={isDarkMode ? '#2E3C41' : '#F3F4F6'}
                                           buttonBgColor={isDarkMode ? '#1c2226' : '#FFFFFF'}
                                           mainTopic={chapter.name} subTopic={topic} nav_link={`/topic/${id}`}/>
                  )
                })
              }
            </div>
        )
        }
      </div>
  )
}