import React from "react";
import ExploreChapterItems from "./Explore/exploreChapterItems";
import exploreChaptersBg from "../../assets/images/exploreChaptersBg.png";
import exploreButtonBg from "../../assets/icons/exploreButtonBg.svg"


export default function LikeTopics({chapterList}) {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return (
      <div className={`flex flex-col mt-10`}>
        <p className={`font-SaM text-2xl mb-4 text-black dark:text-white`}>Topics you’ll like</p>
        <div className={`grid grid-cols-3 gap-12 `}>
          {
            chapterList?.slice(0,3).map(({topic, sub_topic}, index) => (
              <ExploreChapterItems key={index}
                                   bgColor={isDarkMode ? '#2E3C41' : 'white'}
                                   buttonBgColor={isDarkMode ? '#1c2226' : '#F3F4F6'}
                                   mainTopic={topic.topic}
                                   subTopic={sub_topic}
                                   nav_link={`/topic/${topic.id}`}
              />
            ))
          }
        </div>
      </div>
  )
}