import React, {useRef} from "react";
import exploreButton from "../../../assets/icons/chapterBoxButton.svg";
import {useNavigate} from "react-router-dom";
export default function ExploreChapterItems({key, bgColor, buttonBgColor, mainTopic, subTopic, topic_id, nav_link, useLocationState}) {
  const exploreButtonRef = useRef(null);
  const navigate = useNavigate();
  const handleHoverEnter = () => {
    exploreButtonRef.current.classList.add("rotate-90");
    exploreButtonRef.current.classList.add("opacity-100");
    exploreButtonRef.current.classList.add("dark:opacity-100");
    exploreButtonRef.current.classList.add("scale-110");
    exploreButtonRef.current.classList.remove("-rotate-0");
    exploreButtonRef.current.classList.remove("opacity-30");
    exploreButtonRef.current.classList.remove("dark:opacity-60");
    exploreButtonRef.current.classList.remove("scale-100");
  }
  const handleHoverLeave = () => {
    exploreButtonRef.current.classList.add("-rotate-0");
    exploreButtonRef.current.classList.add("opacity-30");
    exploreButtonRef.current.classList.add("dark:opacity-60");
    exploreButtonRef.current.classList.add("scale-100");
    exploreButtonRef.current.classList.remove("rotate-90");
    exploreButtonRef.current.classList.remove("opacity-100");
    exploreButtonRef.current.classList.remove("dark:opacity-100");
    exploreButtonRef.current.classList.remove("scale-110");
  }
  const handleClick = () => {
    navigate(nav_link, {state: useLocationState})
    window.scrollTo({ top: 0, behavior: "instant" });
    console.log("clicked");
  }
  return (
      <div
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
          key={key}
            className={`flex flex-col relative cursor-pointer w-48 h-48 py-6 px-6 min-h-fit bg-cover bg-no-repeat hover:scale-110 hover:translate-x-[5%] hover:translate-y-[5%] transition-transform rounded-3xl rounded-br-[3rem]`}
          onClick={() => {
            handleClick();
          }}
          style={{
            backgroundColor: bgColor,
          }}
      >
        <div className={`flex flex-col`}>
          <p className={`font-SaM text-[#33333399] dark:text-white dark:text-opacity-60 text-sm leading-5 mb-1`}>{mainTopic}</p>
          <p className={`font-SaM text-[#4A4A4A] dark:text-white text-lg`}>{subTopic}</p>
        </div>
        <button
            className={`absolute -bottom-5 -right-4 font-Sa w-24 h-24 text-lg rounded-full`}
            style={{
              backgroundColor: buttonBgColor
            }}
        >
        </button>

        <button ref={exploreButtonRef} className={`absolute opacity-30 bg-greenAccent dark:bg-darkgreenAccent dark:opacity-60 p-3 rounded-full -bottom-1 right-0 w-[4.2rem] text-lg transition-all ease-in-out`}>
          <img src={exploreButton} className={`w-full`} alt={'explore'}/>
        </button>
      </div>
  )
}