import React, {useEffect, useState} from "react";
import edit from "../../assets/icons/blackEdit.svg";
import CollapsibleCarousel from "./collapsibleCarousel";
import ExploreChapterItems from "../learningPlan/exploreChapterItems";
import recommendedChaptersBg from "../../assets/images/recommendedChaptersBg.png";
import recommendedChaptersButtonBg from "../../assets/icons/recommendedChaptersButtonBg.svg";
import axios from "axios";
import BACKEND_URL from "../../constants";
import useAxios from "../../utils/useAxios";



export default function MyChapters() {
  const [mode, setMode] = useState(0);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [recommendedChapters, setRecommendedChapters] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    axios.get(`${BACKEND_URL}/athena_student/learning_plan`).then((response) => {
      setRecommendedChapters(response?.data?.learning_modules);
    });
  }, );
  return (
      <div className={`w-full`}>
        <div className={`flex flex-col items-center w-full`}>
          <div className={`w-fit rounded-xl bg-[#F9FAFB] text-base p-2 flex gap-10 font-SaB mb-10 relative`}>
            <div
                id="slider"
                className={`absolute bottom-[0.2rem] z-0 left-1.5 transition-transform duration-300 ${
                    mode === 1 ? "translate-x-[119%]" : ""
                } w-[44%] h-[85%] bg-white rounded-lg`}
                style={{
                  boxShadow: `0px 2px 6px -2px rgba(212, 212, 212, 1),
                              0px 0px 0px 1px rgba(234, 237, 240, 1),
                              0px -2px 4px 0px rgba(244, 244, 244, 1) inset`,
                }}
            />
            <button
                className={`${mode === 0 ? "text-[#2A323A]" : "text-[#888888] font-Sa"} z-10 px-5 py-1 `}
                onClick={() => {
                  setMode(0);
                }}>
              Learning Plan
            </button>
            <button
                className={`${mode === 1 ? "text-[#2A323A]" : "text-[#888888] font-Sa"} z-10 pl-9 pr-10 py-1 `}
                onClick={() => {
                  setMode(1);
                }}>
              Insights
            </button>
          </div>
          <div className={`flex w-full items-start justify-between`}>
            <div className={`w-fit`}>
              <p className={`font-SaB text-3xl mb-3 dark:text-white`}>My Chapters</p>
              <p className={`font-Sa text-xl dark:text-white`}>Chapters in your learning plan for next examination</p>
            </div>
            <button className={`flex flex-col items-center bg-greenAccent rounded-xl py-3 px-6`}>
              <p className={`font-SaM text-xl text-white`}>Add New</p>
            </button>
          </div>
          <div className={`flex flex-col w-full mt-9`}>
            {
              recommendedChapters?.map(({id, topic}, index) => {
                return (
                  <div className={`flex flex-col mb-10`}>
                    <div className={`flex gap-8`}>
                      {
                        topic?.map(({id, chapter, topic}, key) => {
                          return (
                              <ExploreChapterItems key={key} bgColor={isDarkMode ? '#2E3C41' : '#F3F4F6'}
                                           buttonBgColor={isDarkMode ? '#1c2226' : '#FFFFFF'}
                                           mainTopic={chapter.name} subTopic={topic} nav_link={`/topic/${id}`}/>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
  )
}