import React, {useEffect, useRef, useState} from "react";
import BACKEND_URL from "../constants";
import {useLocation, useNavigate, useParams} from "react-router-dom";
// import Latex from "react-latex-next";
import useAxios from "../utils/useAxios";
import {MathJax, MathJaxContext} from "better-react-mathjax";

export default function StepAssist() {
  const axios = useAxios();
  const { topic_id, subtopic_id } = useParams();
  const percentageCompleted = 50;
  const [currentStep, setCurrentStep] = useState(0);
  const previousURL = useLocation().state?.previousURL;
  const [question, setQuestion] = useState([]);
  const [color, setColor] = useState([]);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [currentStepAssistQuestion, setCurrentStepAssistQuestion] = useState(0);
  const [optionSelected, setOptionSelected] = useState(null);
  const [answer, setAnswer] = useState(null);
  const navigate = useNavigate();
  const [answerTries, setAnswerTries] = useState(0);
  const [stepList, setStepList] = useState([]);
  const [locked, setLocked] = useState([]);
  useEffect(() => {
    if(previousURL !== undefined && topic_id !== undefined && subtopic_id !== undefined) {
      const apiUrl = (previousURL === "topic")
          ? `${BACKEND_URL}/athena_student/subtopic/question?subtopic_id=${subtopic_id}&question_type=step_assist`
          : `${BACKEND_URL}/athena_student/learning_plan/topic/${topic_id}`;
      axios.get(apiUrl).then((response) => {
        setQuestion(response.data);
      });
      setStepList((prev) => [...prev, `Step ${currentStep}`])
    }
  }, [currentStep, previousURL, subtopic_id, topic_id]);

  useEffect(() => {
    if (question.length > 0) {
      setColor(new Array(question.length).fill("#38a4d0"))
      setLocked(new Array(question.length).fill(false))
    }
  }, [question.length]);

    // useEffect(() => {
    //   if (question.length > 0) {
    //     setOptionSelected(new Array(question.length).fill(null));
    //   }
    // }, [question]);

  const handleAnswerSubmit = (answer) => {
    if(answerTries < 2 && !locked[currentStepAssistQuestion]) {
      axios.post(`${BACKEND_URL}/athena_student/subtopic/question?suptopic_id=${subtopic_id}&question_type=step_assist`, [{
        step_id: question?.[currentStepAssistQuestion]?.id,
        answer
      }]).then((response) => {
        if (response?.data?.[0]?.verdict === 1) {
          setQuestionsAnswered(questionsAnswered + 1);
          setColor((prev) => {
            const updatedColor = [...prev];
            updatedColor[currentStepAssistQuestion] = "#0f9d76";
            return updatedColor;
          });
          if(currentStepAssistQuestion < question.length - 1) {
            setCurrentStepAssistQuestion((prev) => prev + 1);
            setStepList((prev) => [...prev, `Step ${currentStep+1}`])
          }
          setAnswerTries(0);
          setLocked((prev) => {
            const updatedLocked = [...prev];
            updatedLocked[currentStepAssistQuestion] = true;
            return updatedLocked;
          })
        } else {
          if(answerTries === 1) {
            axios.get(`${BACKEND_URL}/athena_student/subtopic/question?subtopic_id=${subtopic_id}&question_type=step_assist`).then(
                (response) => {
                  setQuestion(response.data);
                }
            )
            setCurrentStepAssistQuestion(0);
            setStepList(['Step 1'])

            setColor(new Array(question.length).fill("#38a4d0"))
            setAnswerTries(0);
            return ;
          }
          setAnswerTries((prev) => prev + 1);
          setColor((prev) => {
            const updatedColor = [...prev];
            updatedColor[currentStepAssistQuestion] = "#FF5963";
            return updatedColor;
          })
        }
      });
    }
  }

  const handleNext = () => {
    if(currentStepAssistQuestion === question.length - 1) {
      axios.get(`${BACKEND_URL}/athena_student/subtopic/question?subtopic_id=${subtopic_id}&question_type=step_assist`).then(
          (response) => {
            setQuestion(response.data);
            setColor(new Array(question.length).fill("#38a4d0"))
            setCurrentStepAssistQuestion(0);
            setStepList(['Step 1'])
          }
      )
    }
  }
  return (
      <MathJaxContext>
    <div className={`flex flex-col items-center bg-[#F2F2F2] dark:bg-[#2E3C41] min-h-screen`}>
      <p className={`dark:text-white text-xl font-SaM mt-10`}>Step Assist</p>
      <div id="completed-bar" className="h-4 relative mt-4 w-6/12 bg-gray-300 rounded-full">
          <div
              className={`h-full bg-[#0f9d76] dark:bg-[#38a4d0] rounded-full`}
              // style={{width: `${(questionsAnswered/question?.length)*100}%`}}
          />
      </div>
      <div className={`rounded-3xl p-10 w-11/12`}>
        <div className={`rounded-3xl p-10 flex items-center justify-center w-full bg-white dark:bg-darkBackground relative`}>
          <p className={`absolute right-5 top-4 text-2xl font-SaB text-[#0F9D76] dark:text-[#38a4d0]`}>?</p>
          {question?.[0]?.question && (
              <p className={`dark:text-white text-lg`}>
                {/*<MathJax formula={question?.[0].question}/>*/}
                <MathJax>
                  {question?.[0].question}
                </MathJax>
              </p>
          )}
        </div>
        <div className={`flex w-full`}>
          <div className={`flex flex-col w-[45%] mr-10 mt-8 mb-5 bg-white dark:bg-darkBackground rounded-3xl p-6`}>
            <div className={`dark:bg-[#2E3C41] rounded-[2rem] py-10 px-5 mb-3`}>
              <p className={`dark:text-white text-left`}>
                {/*<Latex >*/}
                <MathJax>
                  {question?.[currentStepAssistQuestion]?.step_question}
                </MathJax>
                {/*</Latex>*/}
                {/*<BlockMath math={question?.[currentStepAssistQuestion]?.step_question}/>*/}
              </p>
            </div>

            {
              question?.[currentStepAssistQuestion]?.step_answer.map((option, index) => (
                  <button onClick={() => {
                    handleAnswerSubmit(option);
                  }} key={index}
                          className={`bg-[#F2F2F2] dark:bg-[#2E3C41] dark:text-white text-left rounded-full py-3 px-5 mt-3`}>
                    {/*<Latex >*/}
                    <MathJax>
                      {option}
                    </MathJax>
                    {/*</Latex>*/}
                    {/*<BlockMath math={option}/>*/}
                  </button>
              ))
            }
          </div>
          <div className={`w-[45%] mt-6 flex flex-col`}>
            {
              stepList.map((step, index) => (
                  <div key={index} className={`mt-2.5 rounded-full p-6 w-full`} style={{
                    backgroundColor: color?.[index]
                  }}>
                    <p className={`text-white dark:text-white text-left`}>{`Step ${index + 1}`}</p>
                  </div>
              ))
            }
          </div>

        </div>

        <div className={`flex justify-between w-full`}>
          <button className={`bg-[#1b7f8e] dark:bg-[#38a4d0] text-white rounded-full py-3 px-12 mt-3`}>Back</button>
          <button onClick={() => {handleNext()}} className={`bg-[#0f9d76] dark:bg-[#38a4d0] text-white rounded-full py-3 px-12 mt-3`}>Next</button>
        </div>
      </div>
    </div>
      </MathJaxContext>
  )
}