import React, {useState} from "react";
import Dropdown from "../dropdown";
import StatusButton from "../insights/StatusButton";

export default function OnboardingView2({prevViewState, setPrevViewState, setViewState, selectedCurriculum, setSelectedCurriculum}) {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  // return(
  //     <div className={`w-7/12 flex flex-col justify-evenly items-center`}>
  //       <p className={`text-base font-Sa dark:text-white mb-10`}>TELL US ABOUT YOURSELF</p>
  //       <div className={`flex flex-col relative justify-between items-center mb-10`}>
  //         <p className={`text-xl font-SaM dark:text-white mb-5`}>What is your grade level?</p>
  //         <Dropdown/>
  //       </div>
  //       {/*<input type="text" className={`w-10/12 rounded-full p-4 dark:bg-[#2E3C41] dark:text-white`} placeholder="Enter your name"/>*/}
  //       <div className={`flex justify-between w-10/12`}>
  //         <button className={`w-4/12 bg-white dark:bg-[#2e3c41] text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`} onClick={()=>{
  //           setViewState(0);
  //         }}>
  //           Go Back
  //         </button>
  //         <button
  //             className={`w-4/12 bg-white dark:bg-darkgreenAccent text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`}
  //             onClick={() => {
  //               setViewState(2);
  //             }}>
  //           Continue
  //         </button>
  //       </div>
  //
  //     </div>
  // )
  const [curriculums, setCurriculums] = useState(["IB", "A Levels", "GCSE", "ICSE", "CBSE", "AP", "SAT", "ACT"]);
  return(
      <div className={`w-7/12 flex flex-col justify-evenly items-center`}>
        <p className={`text-base font-Sa dark:text-white mb-10`}>TELL US ABOUT YOURSELF</p>
        <div className={`flex flex-col relative justify-between items-center mb-10`}>
          <p className={`text-xl font-SaM dark:text-white mb-5`}>Which curriculum are you preparing for?</p>
          <div className={`w-full grid grid-cols-4 gap-4 rounded-xl dark:bg-[#2e3c41] p-4`}>
            {
              curriculums.map((curriculum, index) => {
                return(
                    <button key={index} className={`text-black dark:text-white text-base font-SaM w-20 h-20 rounded-lg`} style={{
                      backgroundColor: selectedCurriculum === curriculum ? "#38a4d0" : "#1c2226",
                    }} onClick={()=> {
                      setSelectedCurriculum(curriculum)
                    }}>
                      {curriculum}
                    </button>
                )
              })
            }
          </div>
        </div>
        {/*<input type="text" className={`w-10/12 rounded-full p-4 dark:bg-[#2E3C41] dark:text-white`} placeholder="Enter your name"/>*/}
        <div className={`flex justify-between w-10/12`}>
          <button className={`w-4/12 bg-white dark:bg-[#2e3c41] text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`} onClick={()=>{
            setPrevViewState(0);
            setViewState(0);
          }}>
            Go Back
          </button>
          <button
              className={`w-4/12 bg-white dark:bg-darkgreenAccent disabled:opacity-50 text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`}
              onClick={() => {
                setPrevViewState(1);
                if(selectedCurriculum === 'IB') {
                  setViewState(2);
                }else{
                  setViewState(3);
                }
              }}
              disabled={!selectedCurriculum}>
            Continue
          </button>
        </div>

      </div>
  )
}