import React from "react";
import continueModuleButton from "../../../assets/icons/continueModuleButton.svg";

export default function ContinueModuleItems({key, topic, percentageCompleted}) {
  return (
      <div key={key} className={`flex flex-col relative w-full py-6 px-7 bg-white dark:bg-[#2E3C41] rounded-3xl`}>
        <div className={`flex flex-col`}>
          <p className={`font-Sa text-black dark:text-white text-lg mb-3`}>{topic}</p>
          <div id="completed-bar" className="h-4 w-9/12 bg-gray-300 rounded-full">
            <div
              className={`h-full w-[${percentageCompleted}%] bg-[#FBBC05] dark:bg-darkgreenAccent rounded-full`}
            />
          </div>
        </div>
        <button
            className={`absolute bottom-6 right-7 w-10`}>
          <img src={continueModuleButton} className={`w-full`} alt={'explore'}/>
        </button>
      </div>
  )
}