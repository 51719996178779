import React from "react";
import magicWand from "../../assets/icons/worksheet/magicWandGreen.svg";

export default function WorksheetLoading() {
    return (
        <div className={`flex flex-col w-[78%] items-center`}>
            <span className={`flex justify-center mb-16`}>
                <p className={`text-greenAccent font-SaB text-2xl text-center dark:text-white`}>Generating tailored Worksheet</p>
                <img src={magicWand} className={`w-8 h-8 ml-5`} alt={`magicWand`} />
            </span>
            <div className={`w-11/12 `}>
                {[...Array(5)].map((_, index) => (
                    <div key={index} className="flex flex-col items-center rounded-full w-full mb-14">
                        <div className="w-full h-4 mb-4 rounded-full animate-placeHolderShimmer bg-gradient-to-r from-15% from-[#F4F4F4] dark:from-[#f4f4f43d] to-65% to-[#e1e1e1] dark:to-[#e1e1e117]" />
                        <div className="w-full h-4 rounded-full animate-placeHolderShimmer bg-gradient-to-r from-15% from-[#F4F4F4] dark:from-[#f4f4f43d] to-65% to-[#e1e1e1] dark:to-[#e1e1e117]" />
                    </div>
                ))}
            </div>
        </div>
    );
}
