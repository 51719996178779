import React from "react";
import ContinueModuleItems from "./continueModuleItems";

export default function ContinueModule({chapterList}) {

  return(
      <div className={`flex flex-col mt-10 w-full`}>
        <p className={`font-SaM text-2xl text-black dark:text-white mb-4`}>Continue Module</p>
        <div className={`flex flex-col gap-4`}>
          {
            chapterList?.map(({topic, }, index) => (
                <ContinueModuleItems key={index} topic={topic.topic} percentageCompleted={50} />
            ))
          }
        </div>
      </div>
  )
}