import React from "react";
import startPracticeBannerBg from "../../assets/images/startPracticeBannerBg.png";
import {useNavigate} from "react-router-dom";


export default function ChapterCard({chapter, bgColor}){
  const navigate = useNavigate();
  return(
        <div className={`flex justify-between w-full h-36 items-center py-9 px-10 bg-contain bg-no-repeat rounded-3xl`}
          style={{
            backgroundImage: `url(${startPracticeBannerBg})`,
            backgroundPositionX: '5rem',
            backgroundColor: bgColor,

          }}>
        <div className={`flex flex-col`}>
          <p className={`font-Sa ${bgColor==="white" ? 'text-black' : 'text-white'} text-base mb-1.5 w-9/12`}>{chapter.topic.topic}</p>
          <p className={`font-SaM ${bgColor==="white" ? 'text-black' : 'text-white'} text-lg mb-1.5 w-full`}>{chapter.sub_topic}</p>
        </div>
        <button className={`${bgColor==="white" ? 'bg-[#2E3C41] text-white' : 'bg-white text-black'} font-Sa h-11 text-lg rounded-lg px-5 py-2 `} style={{
          boxShadow:`0px 4px 20px 0px rgba(0, 0, 0, 0.05)`,
        }} onClick={()=>{
          navigate(`/topic/${chapter?.topic?.id}`)
        }} >
          Continue
        </button>
      </div>
  )
}