import React, {useState} from "react";
import addDocument from "../../assets/icons/worksheet/addDocument.svg";
import documentType from "../../assets/icons/worksheet/documentType.svg";
export default function EvalFlow1({hiddenFileInput, hiddenFileInputButton, setFiles}){
  const [mode, setMode] = useState(0);
  const handleClick = () => {
      hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const filesUploaded = event.target.files;
    const filesArray = Array.from(filesUploaded).map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });
    setFiles(filesArray);
  };
  return (
      <div className={`flex flex-col items-center w-full`}>
        <p className={`text-4xl font-SaB text-center mb-4 dark:text-white`}>Correct your worksheet here!</p>
        <p className={`text-base font-Sa w-6/12 text-center mb-11 dark:text-white`}>Upload your mock papers or worksheets so we can tell
          you where to improve!</p>
        <div className={`w-fit rounded-full bg-[#e1e4e7] dark:bg-[#2E3C41] text-base p-2 flex font-Sa mb-10 relative`}>
          <div
              className={`absolute bottom-1 z-0 left-1 w-1/2 transition-transform duration-300 ${
                  mode === 1 ? "translate-x-[95%]" : ""
              } h-[85%] bg-greenAccent dark:bg-darkgreenAccent rounded-full`}
          />
          <button
              className={`${mode === 0 ? "text-white" : "text-black dark:text-[#ffffff9e]"} z-10 px-9 py-2 `}
              onClick={() => {
                setMode(0);
              }}>
            Browse File
          </button>
          <button
              className={`${mode === 1 ? "text-white" : "text-black dark:text-[#ffffff9e]"} z-10 px-9 py-2 `}
              onClick={() => {
                setMode(1);
              }}>
            Google Drive
          </button>
        </div>
        <div className={`rounded-2xl p-3 bg-[#F0F0F0] dark:bg-[#2E3C41] flex items-center justify-center w-8/12 mb-10`}>
          <div
              className={`rounded-xl border border-[#DADADA] dark:border-[#2B2B2B] bg-white dark:bg-[#2E3C41] px-11 py-12 flex flex-col items-center justify-center w-full`}>
            <div className="w-full flex flex-col items-center py-5 rounded-ful mb-7">
              <button type="button" onClick={handleClick} ref={hiddenFileInputButton}
                      className="flex justify-center rounded-full border-[#C4C4C4] border items-center mb-5 p-3">
                <img src={addDocument} className="w-10 dark:invert" alt={"add document"}/>
              </button>
              <p className="font-SaM text-base text-center w-4/12 dark:text-white">
                Drag and Drop
                <br/> or click to upload your file
              </p>
              <input type="file" className="hidden" ref={hiddenFileInput} onChange={handleChange} multiple/>
            </div>
            <div className={`flex items-center mb-5`}>
              <img src={documentType} className={`w-3 h-4 mr-2`} alt={"document type"}/>
              <p className={`uppercase text-[#999] text-SaM text-sm`}>pdf, jpeg, png, heic</p>
            </div>
          </div>
        </div>
      </div>
  )
}