import React from "react";
import Step from "./step";
import "./style.css";
export default function Roadmap({topics, currentTopic, setCurrentTopic}){
  const lineStyle = {
    '--gradient-height': `${((currentTopic+1)*(1.05)/(topics?.length))*100}%`,
  };

  return (
      <div className="flex w-[37%] flex-col relative items-start pt-32 bg-white dark:bg-darkBackground">
        <div className={`flex absolute left-[32.2%] justify-center items-center `}
          style={{
            height: `${topics?.length*30}vh`
          }}
        >
          <div className={`dashed-gradient-line`} style={lineStyle}/>
        </div>
        <div className="w-9/12 absolute -left-[4%] h-screen ">
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-col w-full gap-20">
              {
                topics?.map((topic, index) => {
                  return (
                      <Step topic_id={topic.id} number={index} name={topic?.sub_topic} percentageCompleted={50} currentTopic={currentTopic} setCurrentTopic={setCurrentTopic}/>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
  )

}