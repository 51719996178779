import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
export default function PdfPreview({ src, key, showNumPages = false, name = null}) {
    const canvasRef = useRef(null);
    const [numPages, setNumPages] = useState(null);
    useEffect(() => {
        if (canvasRef.current) {
            canvasRef.current.style.borderRadius = "1rem";
        }
    }, [canvasRef]);
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    return (
        <div className={`w-52 h-full flex flex-col items-center`}>
            <div id={key} className={`bg-[#F0F0F0] dark:bg-[#2E3C41] w-fit h-full flex flex-col justify-center px-4 py-4 rounded-lg`} >
                <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page style={{borderRadius: "0.5rem"}} className={"rounded-lg"} pageNumber={1} renderTextLayer={false} canvasRef={canvasRef} renderAnnotationLayer={false} scale={0.3}  />
                </Document>
            </div>
            {name!==null && (
                <p className={`w-full text-base font-SaM text-center mt-3 dark:text-white`}>{name}</p>
            )}
            {showNumPages && (
                <p className={`w-full text-sm font-SaM text-center mt-1 dark:text-white`}>
                    {numPages} Pages
                </p>
            )}
        </div>
    );
}
