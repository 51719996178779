import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceArea, Dot } from "recharts";

export default function ScoreGraph() {
    const a = 0.05;

    const downwardData = Array.from({ length: 105 }, (_, x) => ({
        x: x,
        y: -a * x * x + 10.5 * x,
    })).filter(({ y }) => y >= 0);

    const upwardData = Array.from({ length: 97 }, (_, x) => ({
        x: x + 105, // Assuming the upward parabola starts where the downward ends
        y: a * x * x + 551.25 ,
    }));

    const data = [...downwardData, ...upwardData];

    const findYCoordinate = (x) => {
        if (x <= 104) {
            return -a * x * x + 10.5 * x;
        }
        else if (x > 104 && x <= 200) {
            x = x - 105;
            return a * x * x + 551.25 ;
        }
        else {
            return null;
        }
    }

    const dataMax = 1016;
    const xCoordinate = 139;
    const yCoordinate = findYCoordinate(xCoordinate);
    const CustomDot = ({ cx, cy, value }) => {
        if (value === yCoordinate) {
            return <Dot cx={cx} cy={cy} r={10} fill="#1B7F8E" />;
        }
        return null;
    };
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    return (
        <div className={`flex w-full`}>
            <div className={`flex h-[89%] relative w-1/4 bg-transparent flex-col border-[#C5C5C5] border-[1.5px] mt-[0.25rem] -mr-[4.1rem]`}>
                <div className={`px-4 py-[1.13rem] flex flex-col justify-start border-[#C5C5C5] border-[1.5px] text-sm ${xCoordinate >= 170 && xCoordinate < 200 ? 'text-greenAccent dark:text-white bg-greenAccent bg-opacity-15 border-2 border-r-0' : 'text-[#767676]'}`}>
                    <p className={`font-SaB`}>Superior</p>
                    <p className={`font-Sa`}>170-200</p>
                </div>
                <div className={`px-4 pt-[1.15rem] pb-[1.1rem] text-sm border-[#C5C5C5] border-[1.5px] ${xCoordinate >= 80 && xCoordinate < 170 ? 'text-greenAccent dark:text-white bg-greenAccent bg-opacity-15 border-r-0' : 'text-[#767676] '}`}>
                    <p className={`font-SaB`}>Established</p>
                    <p className={`font-Sa`}>80-169</p>
                </div>
                <div className={`px-4 pt-[1.13rem] pb-[1.1rem] text-sm border-[#C5C5C5] border-[1.5px] ${xCoordinate >= 30 && xCoordinate < 80 ? 'text-greenAccent dark:text-white bg-greenAccent bg-opacity-15 border-r-0' : 'text-[#767676] '}`}>
                    <p className={`font-SaB`}>Developing</p>
                    <p className={`font-Sa`}>30-79</p>
                </div>
                <div className={`px-4 pt-[1.12rem] pb-[1.1rem] text-sm border-[#C5C5C5] border-[1.5px] border-b-[1.8px]  ${xCoordinate >= 0 && xCoordinate < 30 ? 'text-greenAccent dark:text-white bg-greenAccent bg-opacity-15 border-r-0' : 'text-[#767676]'}`}>
                    <p className={`font-SaB`}>Limited</p>
                    <p className={`font-Sa`}>0-29</p>
                </div>
            </div>

            <ResponsiveContainer width="100%" height={350}>
                <LineChart data={data} >
                    <CartesianGrid stroke="#C5C5C5" strokeWidth={"1px"}/>
                    <XAxis
                        tickSize={0}
                        dataKey="x"
                        type="number"
                        strokeWidth={0}
                        domain={[-10, 210]}
                        tickFormatter={() => ""} // Return an empty string for tick labels
                        tick={false}
                        // label={{ value: "Score", angle: -90, position: "insideLeft" }}
                    />
                    <YAxis
                        tickSize={0}
                        dataKey="y"
                        stroke={"#C5C5C5"}
                        strokeWidth={"0px"}
                        domain={[0, dataMax]}
                        tickLine={true} // Ensure tick lines are visible
                        axisLine={true} // Ensure the axis line is visible
                        tickFormatter={() => ""} // Return an empty string for tick labels
                        ticks={Array.from({length: 8}, (_, i) => ((dataMax) / 8) * i)} // Generate 8 equally spaced ticks
                    />
                    <ReferenceArea y1={0} y2={dataMax/4} stroke={`#C5C5C5`} fillOpacity={(xCoordinate >= 0 && xCoordinate < 30) ? 0.15 : 0}  strokeWidth={1.5} fill={`${xCoordinate >= 0 && xCoordinate < 30 ? '#1B7F8E' : 'white'}`}/>
                    <ReferenceArea y1={dataMax/4} y2={dataMax/2} stroke={`#C5C5C5`} fillOpacity={(xCoordinate >= 30 && xCoordinate < 80) ? 0.15 : 0} strokeWidth={1.5} fill={`${xCoordinate >= 30 && xCoordinate < 80 ? '#1B7F8E' : 'white'}`}/>
                    <ReferenceArea y1={dataMax/2} y2={(3 * dataMax)/4} stroke={`#C5C5C5`} fillOpacity={(xCoordinate >= 80 && xCoordinate < 170) ? 0.15 : 0} strokeWidth={1.5} fill={`${xCoordinate >= 80 && xCoordinate < 170 ? '#1B7F8E' : 'white'}`}/>
                    <ReferenceArea y1={(3 * dataMax)/4} y2={dataMax} stroke={`#C5C5C5`} fillOpacity={(xCoordinate >= 170 && xCoordinate < 200) ? 0.15 : 0} strokeWidth={1.5} fill={`${xCoordinate >= 170 && xCoordinate < 200 ? '#1B7F8E' : 'white'}`}/>
                    <Line type="monotone" dataKey="y" stroke="#afb2b3" strokeWidth="3px" dot={<CustomDot/>}
                          activeDot={{r: 0}}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

