import React from "react";
import {useNavigate} from "react-router-dom";


function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
export default function ChapterTopic({topic, number}) {
  const navigate = useNavigate();
  return(
      <div
          className={`flex flex-col py-16 px-14 w-11/12 max-w-[30rem] bg-[#F3F4F6] dark:bg-[#2E3C41] rounded-[2.125rem]`}>
        <span className={`flex justify-between w-full text-darkgreenAccent text-xl mb-8`}>
          <p className={`font-SaB`}>Topic {number}</p>
          <p className={`font-SaM`}>{50}%</p>
        </span>
          <p className={`font-SaM text-2xl text-[#4A4A4A] dark:text-white mb-4`}>{topic?.sub_topic}</p>
          <p className={`font-Sa text-lg text-[#333333] dark:text-[#FFFFFF99] opacity-80 mb-8`}>
              {/*{topic?.description}*/}
              {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et*/}
              {/*dolore magna aliqua.*/}
          </p>
          <div className={`flex gap-9 mb-8`}>
              <div>
                  <div
                      className={`rounded-2xl border-[#5AA1AD] dark:border-darkgreenAccent border-2 p-5 font-SaM text-center text-xl text-darkgreenAccent mb-2`}>
                      {10}
                  </div>
                  <p className={`font-SaM text-sm text-[#333333] dark:text-[#828282] opacity-80 text-center`}>Avg
                      Sums</p>
              </div>
              <div>
                  <div
                      className={`rounded-2xl border-[#5AA1AD] dark:border-darkgreenAccent border-2 p-5 font-SaM text-center text-xl text-darkgreenAccent mb-2`}>{formatDate(topic?.last_visited)}</div>
                  <p className={`font-SaM text-sm text-[#333333] dark:text-[#828282] opacity-80 text-center`}>Last
                      Solved</p>
              </div>
              <div>
                  <div
                      className={`rounded-2xl border-[#5AA1AD] dark:border-darkgreenAccent border-2 p-5 font-SaM text-center text-xl text-darkgreenAccent mb-2`}>{20}</div>
                  <p className={`font-SaM text-sm text-[#333333] dark:text-[#828282] opacity-80 text-center`}>Avg Time
                      Spent</p>
              </div>
          </div>
          <div>
              <div className={`flex justify-center gap-x-10`}>
                  <button
                      className={`font-Sa text-xl bg-white border-greenAccent dark:bg-darkgreenAccent dark:border-darkgreenAccent hover:dark:bg-darkBackground transition-colors duration-300 border-2 text-white px-5 py-3 rounded-2xl w-fit`}
                      onClick={() => {
                          navigate(`/mcq/${topic?.topic?.id}/${topic?.id}`, {state: {previousURL: 'topic'}})
                      }}>{(topic?.percentageCompleted === 100) ? "Next" : "Start MCQ"}</button>

                  <button
                      className={`font-Sa text-xl bg-greenAccent dark:bg-darkgreenAccent dark:border-darkgreenAccent hover:dark:bg-darkBackground transition-colors duration-300 border-2 text-white px-5 py-3 rounded-2xl w-fit`}
                      onClick={() => {
                          navigate(`/stepAssist/${topic?.topic?.id}/${topic?.id}/`, {state: {previousURL: 'topic'}})
                      }}>{(topic?.percentageCompleted === 100) ? "Next" : "Step Assist"}</button>
              </div>
          </div>
      </div>
  )
}