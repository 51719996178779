import React from "react";
import worksheetPreview from "../../assets/images/worksheetPreview.png";

export default function WorksheetGeneratedPreview() {
  return(
      <div className={`flex flex-col w-full items-center`}>
        <div className={`w-2/12 bg-[#F0F0F0] dark:bg-[#262626] rounded-2xl px-6 py-4 mb-16`}>
          <img src={worksheetPreview} alt={`worksheetPreview`} className={`w-full h-full rounded-2xl`}/>
        </div>
        <p className={`text-4xl font-SaB text-center mb-4 dark:text-white`}>Worksheet Generated</p>
        <p className={`text-xl font-SaM text-center mb-8 w-[40%] dark:text-white`}>Congratulations! Your worksheet is generated. You can proceed to solve it.</p>
        <div className={`flex justify-around`}>
          <button className={`bg-white dark:bg-black border-[#D5D5D5] dark:border-white border text-black dark:text-white text-lg font-SaM px-5 py-2 rounded-lg mr-5`}>
            Save PDF
          </button>
          <button className={`bg-greenAccent dark:bg-darkgreenAccent text-white text-lg font-SaM px-5 py-2 rounded-lg`}>
            Solve with Athena
          </button>
        </div>
      </div>
  )
}