import React, { useEffect, useState } from "react";
import ProfileCard from "../components/Navbar/profileCard";
import Navbar from "../components/Navbar/navbar";
import SearchBar from "../components/searchBar";
import Banner from "../components/homepage/banner";
import ContinuePractice from "../components/homepage/Practice/continuePractice";
import StartPractice from "../components/homepage/Practice/startPractice";
import ExploreChapters from "../components/homepage/Explore/exploreChapters";
import Calender from "../components/homepage/Calender/calender";
import LikeTopics from "../components/homepage/likeTopics";
import ContinueModule from "../components/homepage/ContinueModule/continueModule";
import ProgressRing from "../components/ProgressRing";
import useAxios from "../utils/useAxios";
import BACKEND_URL from "../constants";
import Header from "../components/Header";
import {useNavigate} from "react-router-dom";
export default function Home() {

  const axios = useAxios();
  const [subjectStarted, setSubjectStarted] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
      axios.get(`${BACKEND_URL}/athena_student/home/`).then((response) => {
          setData(response.data);
      })
  }, []);
  return (
      <div className={`bg-background dark:bg-darkBackground w-full flex select-none`}>
        <div className={`flex flex-col w-full`}>
          <Header/>
          <div className={`flex w-full`}>
            <Navbar/>
            <div className={`w-[50%] p-10 dark:bg-darkBackground mr-5`}>
              {/*<SearchBar placeholder={"Search"} className={"rounded-xl sm:text-sm sm:leading-6 placeholder:text-sm dark:text-white pl-12 pr-5"}/>*/}
              <Banner/>
              {!subjectStarted && <StartPractice/>}
              {subjectStarted && <ContinuePractice chapterList={data?.continue_practicing}/>}
              <ContinueModule chapterList={data?.continue_module}/>
              <LikeTopics chapterList={data?.random_topics}/>
              <ExploreChapters chapterList={data?.explore_topics}/>
            </div>
            <div className={`w-[25%] h-full pt-10`}>
              <div className={`bg-white h-fit rounded-2xl flex flex-col items-center  dark:bg-[#2E3C41]`}>
                <Calender/>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="flex items-center justify-center min-h-screen">*/}
        {/*  <ProgressRing radius={60} stroke={4} progress={70}/>*/}
        {/*</div>*/}
      </div>
  );
}
