import './App.css';
import {createBrowserRouter, Route, RouterProvider, Routes} from 'react-router-dom';
import Home from "./pages/home";
import ChapterListing from "./pages/chapterListing";
import ChapterDetail from "./pages/chapterDetail";
import Worksheet from "./pages/worksheet";
import LearningPlan from "./pages/learningPlan";
import Insights from "./pages/insights";
import EvalWorksheet from "./pages/evalWorksheet";
import Profile from "./pages/profile";
import Mcq from "./pages/mcq";
import StepAssist from "./pages/stepAssist";
import Onboarding from "./pages/onboarding";
import Login from "./pages/login";
import Main_wrapper from "./layouts/Main_wrapper";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Dashboard from "./pages/dashboard";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPdTvs_PI13Ug1C_nuoVB1IXCC9yrpATU",
  authDomain: "athena-frontend-3366c.firebaseapp.com",
  projectId: "athena-frontend-3366c",
  storageBucket: "athena-frontend-3366c.appspot.com",
  messagingSenderId: "903772656102",
  appId: "1:903772656102:web:23cf4dcf6147d9d3050627",
  measurementId: "G-E7B9G6NEF0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export {analytics};

function App() {

  return (
     <Main_wrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chapters" element={<ChapterListing />} />
        <Route path="/topic/:id" element={<ChapterDetail />} />
        <Route path="/worksheets" element={<Worksheet />} />
        <Route path="/evaluateWorksheet/:id" element={<EvalWorksheet />} />
        <Route path="/learningPlan" element={<LearningPlan />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/mcq/:topic_id/:subtopic_id" element={<Mcq />} />
        <Route path="/stepAssist/:topic_id/:subtopic_id" element={<StepAssist />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Main_wrapper>
  );
}

export default App;
