import React, {useEffect, useState} from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import nextMonthButton from "../../../assets/icons/nextMonth.svg"
import nextMonthButtonDark from "../../../assets/icons/nextMonthDark.svg"
import emptyCalender from "../../../assets/images/emptyCalender.png"
import CalenderTaskList from "./calenderTaskList";
export default function Calender(){
  const [selectedDay, setSelectedDay] = useState(null);
  const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
  // useEffect(() => {
  //   if (selectedDay) {
  //     const day = selectedDay.day;
  //     const month = selectedDay.month;
  //     const year = selectedDay.year;
  //     const date = `${day}/${month}/${year}`;
  //     handleDateChange(date);
  //   }
  // }, [handleDateChange, selectedDay]);

  useEffect(() => {
    const calenderButtons = document.querySelectorAll(".Calendar__monthArrowWrapper");
    console.log(calenderButtons, "calenderButtons")
    const previousButton = calenderButtons[0];
    const nextButton = calenderButtons[1];
    // remove all the inner elements of the previous button
    if(isDarkMode){
      nextButton.innerHTML = `<img src=${nextMonthButtonDark} alt='next month' class="-rotate-90 dark:invert" />`;
      previousButton.innerHTML = `<img src=${nextMonthButtonDark} alt='previous month' class="-rotate-90 dark:invert" />`;
    }else{
      nextButton.innerHTML = `<img src=${nextMonthButton} alt='next month' class="-rotate-90" />`;
      previousButton.innerHTML = `<img src=${nextMonthButton} alt='previous month' class="-rotate-90" />`;
    }
  }, []);

  const taskList = [
    {
      topic: "Trigonometry",
      duration: "20 mins",
    },
    {
      topic: "Calculus MCQ",
      duration: "20 mins",
    },
    {
      topic: "Danger Topics",
      duration: "20 mins",
    },
  ]
  return (
      <div className={`flex flex-col w-full items-center py-5 mb-10 mx-5 rounded-3xl dark:bg-[#2E3C41]`}>
        <Calendar
          value={selectedDay}
          onChange={setSelectedDay}
          colorPrimary={`#1B7F8E`}
          shouldHighlightWeekends
          calendarClassName={`bg-white dark:bg-[#2E3C41] mb-10`}
          calendarTodayClassName={`bg-[#1B7F8E] dark:bg-darkgreenAccent text-white`}
          calendarSelectedDayClassName={`rounded-full`}
        />
        {/*<hr className={`my-10 border-t-2 w-10/12 mx-10 border-gray-300`}/>*/}
        {/*<p className={`font-Sa text-xl mb-5 px-10 text-center`} >You don’t have anything on your calendar yet. Start learning now!</p>*/}
        {/*<img className={`mx-5`} src={emptyCalender} alt="empty calender"/>*/}
        <p className={`font-SaM text-xl dark:text-white w-full px-7 text-left`} >Today's Tasks</p>
        <CalenderTaskList taskList={taskList}/>
      </div>
  );
};