import React from "react";
import uploadPlus from "../../assets/icons/worksheet/uploadPlus.svg";
import PdfPreview from "./pdfPreview";


export default function EvalFlow3({files, setUploading, setPercentage, setFiles}) {
  return (
      <div className={`flex flex-col w-full`}>
        <p className={`text-4xl font-SaB text-black mb-4 dark:text-white`}>Worksheets</p>
        <div className={`w-full flex flex-wrap gap-10 mb-36`}>
          <div
              className={`w-52 mt-0.5 h-[16.5rem] flex flex-col items-center justify-center border-2 border-dashed border-black dark:border-[#4B4B4B] rounded-2xl`}>
            <button
                type="button"
                onClick={() => {
                  setUploading(false);
                  setFiles([]);
                  setPercentage(0);
                }}
                className={`w-fit h-fit rounded-full mb-6`}>
              <img src={uploadPlus} alt="upload plus" className={`w-12 h-12`}/>
            </button>
            <p className={`text-base font-SaM text-center dark:text-white`}>Upload New Worksheet</p>
          </div>
          {files.map((file, index) => (
              <div key={index} className={`flex flex-col items-center`}>
                {file.data.type.startsWith("image/") ? (
                    <img src={file.preview} alt="Preview" className={`w-44`}/>
                ) : file.data.type === "application/pdf" ? (
                    <PdfPreview src={file.preview} showNumPages={true} name={file.data.name}/>
                ) : (
                    <p>Preview not available for this file type</p>
                )}
                {/*<p className={`text-base font-SaM text-center mt-3 w-44`}>{file.data.name}</p>*/}
                {/*<p className={`text-sm font-SaM text-center mt-1 w-40`}>{file.numPages} Pages</p>*/}
              </div>
          ))}
        </div>
      </div>
  )
}