import React, {useEffect, useState} from "react";
import TopicCard from "./topicCard";

export default function DangerTopics({data}) {
    const [mode, setMode] = useState(0);
    const [displayData, setDisplayData] = useState(null);
    useEffect(() => {
        if (mode === 1) {
            setDisplayData(data);
        } else {
            setDisplayData(data?.slice(0, 2));
        }
    }, [mode]);
    return (
        <div className={`w-full mb-20`}>
            <div className={`inline-flex mb-10 justify-between w-full items-center`}>
                <h1 className={`text-2xl font-SaM dark:text-white`}>Danger Topics</h1>
                <button className={`bg-[#2e3c41] rounded-lg px-8 py-2 font-SaM text-white`} onClick={()=>{setMode(mode === 0 ? 1 : 0)}}>{ mode===0 ? `View All` : `View Less`}</button>
            </div>
            <div className={`w-full grid grid-cols-2 gap-12 `}>
                {
                    displayData?.map((data, index) => (
                        <TopicCard key={index}
                                   type={"Danger"}
                                   functions={data}
                                   // reason={reason}
                                   // score={score}
                                   // wrongKnowledge={wrongKnowledge}
                                   marksGained={9}
                        />
                    ))
                }
            </div>
        </div>
    );
}
