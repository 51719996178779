import React, { useState } from "react";
import Dropdown from "../dropdown";
import FocusedInput from "./FocusedInput";
import chimplord_1 from "../../assets/images/chimplord_1.png";
import chimplord_3 from "../../assets/images/chimplord_3.png";
import { useNavigate } from "react-router-dom";
export default function OnboardingView1({
    setViewState,
    name,
    setName,
    email,
    setEmail,
    school,
    setSchool,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    gender,
    setGender,
    grade,
    setGrade,
}) {
    const navigate = useNavigate();
    return (
        <div className={`w-7/12 flex flex-col bg-[#2E3C41] py-10 rounded-2xl justify-evenly items-center`}>
            <p className={`font-Nasa text-4xl dark:text-white mb-5`}>SIGN UP</p>
            <div className={`flex flex-col gap-y-4 w-11/12 mb-5`}>
                <FocusedInput placeholder="Enter Name" value={name} onChange={(event) => setName(event.target.value)} />
                <FocusedInput placeholder="Enter Email" type={"email"} value={email} onChange={(event) => setEmail(event.target.value)} />
                <FocusedInput placeholder="Enter School Name" value={school} onChange={(event) => setSchool(event.target.value)} />
                <div className={`flex w-full gap-x-4`}>
                    <FocusedInput
                        placeholder="Enter Password"
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <FocusedInput
                        placeholder="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                </div>
                <div className={`flex w-full gap-x-4`}>
                    <Dropdown data={["Male", "Female", "Other"]} placeholder={"Select Gender..."} option={gender} setOption={setGender} />
                    <Dropdown data={["8", "9", "10"]} placeholder={"Select Grade..."} option={grade} setOption={setGrade} />
                </div>
            </div>

            {/*<input type="text" className={`w-10/12 rounded-full p-4 dark:bg-[#2E3C41] dark:text-white`} placeholder="Enter your name"/>*/}
            <div className={`w-11/12 flex justify-between`}>
                <button
                    className={`w-3/12 bg-white dark:bg-darkgreenAccent text-black dark:text-white text-base font-SaM px-5 py-2 rounded-xl`}
                    onClick={() => {
                        navigate("/login");
                    }}>
                    Login
                </button>
                <button
                    className={`w-3/12 bg-white dark:bg-darkgreenAccent disabled:opacity-50 text-black dark:text-white text-base font-SaM px-5 py-2 rounded-xl`}
                    onClick={() => {
                        setViewState(1);
                    }}
                    disabled={!(name && email && school && password && confirmPassword && gender && grade)}>
                    Next
                </button>
            </div>
        </div>
    );
}
