import React, {useState} from "react";
import Navbar from "../components/Navbar/navbar";
import CreateLearningSchedule from "../components/learningPlan/createLearningSchedule";
import LearningSchedule from "../components/learningPlan/learningSchedule";
import MyChapters from "../components/learningPlan/myChapters";
import Header from "../components/Header";
export default function LearningPlan() {
  const [viewMode, setViewMode] = useState(0);

  return (
      <div className={`bg-white dark:bg-darkBackground w-full min-h-screen flex select-none`}>
        <div className={`flex flex-col w-full`}>
            <Header/>
          <div className={`flex w-full`}>
            <Navbar />
            <div className={`w-[70%] pl-10 py-5 bg-white dark:bg-darkBackground relative flex flex-col items-center`}>
                {viewMode===0 && <LearningSchedule setViewMode={setViewMode}/> }
                {viewMode===1  && <CreateLearningSchedule setViewMode={setViewMode}/>}
                {viewMode===2 && <MyChapters/>}
            </div>
          </div>
        </div>
      </div>

)
}