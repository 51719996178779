import React, {useState} from "react";
import Navbar from "../components/Navbar/navbar";
import WorksheetGenerationForm from "../components/worksheet/worksheetGenerationForm";
import WorksheetLoading from "../components/worksheet/worksheetLoading";
import WorksheetGeneratedPreview from "../components/worksheet/worksheetGeneratedPreview";
import WorksheetEvaluate from "../components/worksheet/worksheetEvaluate";
export default function Worksheet() {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(0);
  const [generated, setGenerated] = useState(false);
  const [evalUpload, setEvalUpload] = useState(true);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  // const []
  return (
      <div className={`bg-background w-full flex select-none`}>
        <Navbar/>
        <div className={`w-[78%] px-10 py-14 bg-white dark:bg-darkBackground relative flex flex-col items-center`}>
          <div className={`w-fit rounded-xl bg-[#F9FAFB] dark:bg-darkBackground text-base p-2 flex gap-3 font-SaB mb-10 relative`}>
            <div id="slider"
                className={`absolute bottom-1 z-0 left-1.5  transition-transform duration-300 ${mode === 1 ? 'translate-x-[102.5%] w-[47%] ' : 'w-[48%]'} h-[85%] bg-white dark:bg-[#2E3C41] rounded-lg`}
                style={{
                  boxShadow: `${isDarkMode ? `
                    0px -2px 4px 0px #0A0A0A inset, 
                    0px 0px 0px 1px #0F0F0F, 
                    0px 2px 6px -2px #2B2B2B` : 
                    `0px 2px 6px -2px rgba(212, 212, 212, 1),
                    0px 0px 0px 1px rgba(234, 237, 240, 1),
                    0px -2px 4px 0px rgba(244, 244, 244, 1) inset`
                  }`
                }}
              />
            <button className={`${(mode === 0) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 px-5 py-2 `}
                    onClick={() => {
                      setMode(0)
                    }}>Generate
            </button>
            <button className={`${(mode === 1) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 px-5 py-2 `}
                    onClick={() => {
                      setMode(1)
                    }}>Evaluate
            </button>
          </div>
          {mode===0 && !generated && !loading && <WorksheetGenerationForm/>}
          {mode===0 && loading && <WorksheetLoading/>}
          {mode===0 && generated && !loading && <WorksheetGeneratedPreview/>}
          {mode===1 && evalUpload && <WorksheetEvaluate/>}
        </div>
      </div>
  )
}