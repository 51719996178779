import React, { useState } from "react";

const FocusedInput = ({ placeholder, value, onChange, width = "w-full", type = "text" }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <input
      className={`${width} rounded-2xl p-4 dark:bg-darkBackground dark:placeholder:text-white font-Sa dark:text-white focus:ring-transparent focus:outline-none`}
      placeholder={isFocused ? "" : placeholder}
      value={value}
      type={type}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onChange={onChange}
      autoComplete="off"
    />
  );
};

export default FocusedInput;