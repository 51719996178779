import React, {useRef} from "react";
import caretButton from "../../assets/icons/caretButtonBlack.svg";
import ChapterCard from "./chapterCard";
import CarouselImpl from "../CarouselImpl";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";

export default function ContinueLearning({chapters}){

  const chapterEventCardRef = useRef(null);

  let chapterCardList = [];
  for (let i = 0; i < chapters?.length; i++) {
    chapterCardList.push(
      <div
        className={`w-10/12 bg-white dark:bg-darkBackground`}
        ref={chapterEventCardRef}>
        <ChapterCard chapter={chapters[i]} bgColor={(i%3===0) ? "#0F9D76" : (i%3===1 ? "#2E3C41" : "white")}/>
      </div>
    );
  }
  return(
      <div className={`flex flex-col overflow-clip w-full h-52`}>
        <div className={`flex flex-col justify-between w-full h-full`}>
          <p className={`font-SaB text-2xl mb-3 dark:text-white`}>Continue Learning</p>
          {/*<div className={`w-fit flex items-center`}>*/}
          {/*  <button className={`w-6 h-6 mr-5`}>*/}
          {/*    <img src={caretButton}/>*/}
          {/*  </button>*/}
          {/*  <button className={`w-6 h-6 rotate-180`}>*/}
          {/*    <img src={caretButton}/>*/}
          {/*  </button>*/}
          {/*</div>*/}
          <div className={`w-full flex`}>
            <CarouselImpl visibleSlides={(window.innerWidth < 768) ? 1 : 2} minwidth={"34rem"} buttonContainerWidth={"11rem"} buttonBgColor={"white"} buttonTopPosDivisor={2.4}>
              {chapterCardList}
            </CarouselImpl>
            {/*<CarouselProvider className="flex w-full"*/}
            {/*  naturalSlideWidth={100}*/}
            {/*  naturalSlideHeight={125}*/}
            {/*  totalSlides={3}*/}
            {/*                  orientation={"horizontal"}*/}
            {/*>*/}
            {/*  <Slider className="flex" classNameTray={'flex'}>*/}
            {/*    <Slide index={0}>I am the first Slide.</Slide>*/}
            {/*    <Slide index={1}>I am the second Slide.</Slide>*/}
            {/*    <Slide index={2}>I am the third Slide.</Slide>*/}
            {/*  </Slider>*/}
            {/*  <ButtonBack>Back</ButtonBack>*/}
            {/*  <ButtonNext>Next</ButtonNext>*/}
            {/*</CarouselProvider>*/}
          </div>
        </div>
      </div>
  )
}