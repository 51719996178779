import React, {useRef, useState} from "react";
import Navbar from "../components/Navbar/navbar";
import ProfileHeader from "../components/profile/profileHeader";
import profileImage from "../assets/images/profileImage.png";
import CarouselImpl from "../components/CarouselImpl";
import ChapterCard from "../components/chapterListingPage/chapterCard";

export default function Profile() {
  const profile = {
    name: "John Doe",
    designation: "Student",
    organization: "Srishti Manipal Institute",
    profileImage: profileImage,
    aboutMe: "I am a student at Srishti Manipal Institute of Art, Design and Technology. I am currently pursuing my B.Des in User Experience Design. I am passionate about creating user-centric designs and solving real-world problems through design thinking.",
    tags: ["Quick-learner", "Hard-Worker", "Design Thinking"]
  }
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [mode, setMode] = useState(0);
  const chapterEventCardRef = useRef(null);
  const chapters = [
    {
      topic: "Functions",
      subTopic: "Quadratic Equations",
    },
    {
      topic: "Mensuration",
      subTopic: "Cylinder, Cone and Sphere",
    },
    {
      topic: "Functions",
      subTopic: "Quadratic Equations",
    },
    {
      topic: "Mensuration",
      subTopic: "Cylinder, Cone and Sphere",
    },
    {
      topic: "Functions",
      subTopic: "Quadratic Equations",
    },
    {
      topic: "Mensuration",
      subTopic: "Cylinder, Cone and Sphere",
    },
  ];

  let chapterCardList = [];
  for (let i = 0; i < chapters.length; i++) {
    chapterCardList.push(
      <div
        className={`w-11/12 bg-white dark:bg-black`}
        ref={chapterEventCardRef}>
        <ChapterCard chapter={chapters[i]} bgColor={(i%3===0) ? "#60AFBB" : (i%3===1 ? "#398090" : "#496063")}/>
      </div>
    );
  }
  return (
      <div className={`bg-white dark:bg-black w-full flex select-none`}>
        <Navbar/>
        <div className={`w-[78%] dark:bg-black`}>
          <ProfileHeader profile={profile}/>
          <div className={`flex px-24`}>
            <div className={`w-fit rounded-xl text-base p-2 flex gap-3 font-SaB mb-10 relative`}>
              <div id="slider" className={`absolute bottom-1 z-0 left-2 border-b-2 border-greenAccent transition-transform duration-300 ${mode === 1 ? 'translate-x-[120%] w-[42%] ' : 'w-[42%]'} h-[85%]`}/>
              <button
                  className={`${(mode === 0) ? 'text-greenAccent' : 'text-[#888888] font-Sa'} z-10 pr-2 py-1 `}
                  onClick={() => {
                    setMode(0)
                  }}>Overview
              </button>
              <button
                  className={`${(mode === 1) ? 'text-greenAccent' : 'text-[#888888] font-Sa'} z-10 pl-2 py-1 `}
                  onClick={() => {
                    setMode(1)
                  }}>Evaluate
              </button>
            </div>
          </div>
          <div className={`px-[6.5rem]`}>
            <hr className={`w-6/12 mb-8 -mt-11 border-1 border-[#D5D5D5] dark:border-[#262626]`}/>
            {
              mode === 0 ? (
                  <div className={`w-7/12 mb-14`}>
                    <p className={`text-lg font-Sa dark:text-white mb-3`}>About Me</p>
                    <p className={`text-xl font-Sa dark:text-white mb-7`}>{profile.aboutMe}</p>
                    <div className={`flex mb-14 gap-5 flex-wrap`}>
                      {
                        profile.tags.map((tag, index) => {
                          return (
                              <div key={index} className={`bg-[#496063] px-5 py-2 rounded-lg flex items-center justify-center`}>
                                <p className={`font-SaM text-base text-[#496063] dark:text-white`}>{tag}</p>
                              </div>
                          )
                        })
                      }
                    </div>
                  </div>
              ) : (
                  <div className={`w-7/12 mb-14`}>
                  </div>
              )
            }
            <div className={`overflow-hidden`}>
              <p className={`text-lg font-Sa dark:text-white mb-4`}>Favourite Topics</p>
              <CarouselImpl visibleSlides={(window.innerWidth < 768) ? 1 : 2} minwidth={"34rem"} buttonContainerWidth={"11rem"} buttonBgColor={"white"} buttonTopPosDivisor={2.4}>
                {chapterCardList}
              </CarouselImpl>

            </div>
          </div>

        </div>
      </div>
  )
}