import React, { useState, useEffect } from "react";
import bannerBg from "../../assets/images/bannerBg.svg";
import darkBannerBg from "../../assets/images/darkBannerBg.svg";
import BACKEND_URL from "../../constants"; // Import the backend URL constant
import chimplord_1 from "../../assets/images/chimplord_1.png";
import useAxios from "../../utils/useAxios";
export default function Banner() {
  const axios = useAxios();
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [bannerQuote, setBannerQuote] = useState("");
  useEffect(() => {
    axios.get(`${BACKEND_URL}/athena_student/home/quote/`).then((response) => {
      setBannerQuote(response.data);
    });
  }, []);
  return(
      <div className={`w-full flex items-center dark:bg-[#2E3C41] rounded-3xl bg-no-repeat h-64 py-16 px-10`} style={{
        backgroundImage: `url(${isDarkMode ? chimplord_1 : bannerBg})`,
        backgroundPositionY: "center",
        backgroundPositionX: "22rem",
        backgroundSize: "25rem",
      }}>
        <p className={`font-SaM text-4xl leading-normal text-[#474747] dark:text-white w-7/12`}>{bannerQuote}</p>
      </div>
  )
}
