import React, {useRef, useState} from 'react';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';
import correctAnswer from '../../assets/icons/insights/correctAnswer.svg';
import wrongAnswer from '../../assets/icons/insights/wrongAnswer.svg';
import skippedAnswer from '../../assets/icons/insights/skippedAnswer.svg';
import darkCorrectAnswer from '../../assets/icons/insights/darkCorrectAnswer.svg';
import darkWrongAnswer from '../../assets/icons/insights/darkWrongAnswer.svg';
import caretButton from '../../assets/icons/caretButtonBlack.svg';
export default function CollapsibleCarousel ({ question, answer, status, userAnswer, correctAnswerOption }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  let icon;
  switch (status) {
    case 1:
      icon = isDarkMode ? darkCorrectAnswer : correctAnswer;
      break;
    case 0:
      icon = isDarkMode ? darkWrongAnswer : wrongAnswer;
      break;
    case null:
      icon = skippedAnswer;
      break;
    default:
      icon = skippedAnswer;
  }
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
      <div className={`rounded-2xl my-2 w-full dark:bg-darkBackground`}>
        <button
            className={`inline-flex relative w-full dark:bg-[#2E3C41] items-center rounded-2xl text-left py-4 px-7 bg-[#F9FBFB] ${isOpen ? 'rounded-b-none' : ''}`}
            onClick={toggleOpen}
        >
          <div className={`inline-flex flex-col items-center mr-8`}>
            <img className={`h-10 w-10 mb-2`} src={icon} alt="icon"/>
            <p className="text-sm font-SaM text-center dark:text-white">{status === 0 ? "Incorrect" : (status === 1 ? "Correct" : "Skipped")}</p>
          </div>
          <p className="font-SaM dark:text-white w-10/12">
            <Latex>{question}</Latex>
          </p>
          <img className={`absolute top-[30%] right-8 h-12 w-12 ${isOpen ? 'rotate-90' : '-rotate-90'} transition-rotate dark:invert`}
               src={caretButton} alt="icon"/>
        </button>
        <div
            className={`overflow-hidden w-full bg-[#F9FBFB] dark:bg-[#2E3C41] px-14 rounded-b-2xl transition-max-height`}
            style={{maxHeight: isOpen ? `${contentRef.current.scrollHeight + 100}px` : '0'}}
        >


          <div ref={contentRef} className="flex flex-col rounded-lg mb-10 bg-white dark:bg-[#2E3C41] dark:border-[#4D4D4D] dark:border px-7 py-4 w-full">
            {(status === 0) && (
                <div className={`flex justify-between mb-5`}>
                  <div className={`flex gap-2.5 w-fit`}>
                    <div
                        className={`w-fit rounded-full bg-[#FDB153] py-1 px-3 flex items-center justify-center font-Sa text-sm`}>
                      You selected
                    </div>
                    <p className={`dark:text-white`}>
                      <Latex>{userAnswer}</Latex>
                    </p>
                  </div>
                  <div className={`flex gap-2.5 w-fit`}>
                    <div
                        className={`w-fit rounded-full bg-[#00B383] py-1 px-3 flex items-center justify-center font-Sa text-sm`}>
                      Correct Answer
                    </div>
                    <p className={`dark:text-white`}>
                      <Latex>{correctAnswerOption}</Latex>
                    </p>
                  </div>
                </div>
            )
            }
            <p className={`dark:text-white`}>
              <Latex>{answer}</Latex>
            </p>
          </div>
        </div>
      </div>
);
};
