import React from "react";
import homeBlack from "../../assets/icons/navbar/homeBlack.svg";
import homeGreen from "../../assets/icons/navbar/homeGreen.svg";
import athenaChatBlack from "../../assets/icons/navbar/athenaChatBlack.svg";
import athenaChatGreen from "../../assets/icons/navbar/athenaChatGreen.svg";
import chaptersBlack from "../../assets/icons/navbar/chaptersBlack.svg";
import chaptersGreen from "../../assets/icons/navbar/chaptersGreen.svg";
import insightsBlack from "../../assets/icons/navbar/insightsBlack.svg";
import insightsGreen from "../../assets/icons/navbar/insightsGreen.svg";
import practiceBlack from "../../assets/icons/navbar/practiceBlack.svg";
import practiceGreen from "../../assets/icons/navbar/practiceGreen.svg";
import worksheetsBlack from "../../assets/icons/navbar/worksheetsBlack.svg";
import worksheetsGreen from "../../assets/icons/navbar/worksheetsGreen.svg";
import NavbarItems from "./navItems";
import ProfileCard from "./profileCard";
export default function Navbar({width=22}) {
  const navItems = [
    {
      idx: 0,
      icons: [homeBlack, homeGreen],
      label: "Home",
      route: "/",
      activeLinks: [""]
    },
    {
      idx: 1,
      icons: [practiceBlack, practiceGreen],
      label: "Learning Plan",
      route: "/learningPlan",
      activeLinks: ["learningPlan", ]

    },
    {
      idx: 2,
      icons: [chaptersBlack, chaptersGreen],
      label: "Chapters",
      route: "/chapters",
      activeLinks: ["chapters", "topic"]
    },
    {
      idx: 3,
      icons: [worksheetsBlack, worksheetsGreen],
      label: "Worksheets",
      route: "/worksheets",
      activeLinks: ["worksheets"]
    },
    // {
    //   idx: 4,
    //   icons: [athenaChatBlack, athenaChatGreen],
    //   label: "Athena Chat"
    // },
    {
      idx: 5,
      icons: [insightsBlack, insightsGreen],
      label: "Insights",
      route: "/dashboard",
      activeLinks: ["insights", "dashboard"]
    },
  ];
  return (
      <div className={`py-10 px-9 min-w-60 min-h-screen bg-background dark:bg-darkBackground`}
        style={{
          width: `${width}%`,
        }}
      >
        {/*<ProfileCard profile={{*/}
        {/*  name: "Siddhant Rander",*/}
        {/*  xp: 450,*/}
        {/*  img: "https://i.pravatar.cc/300"*/}
        {/*}}/>*/}
        <div className={`flex flex-col py-4`}>
          {
            navItems.map(({idx, icons, label, route, activeLinks}, index) => (
                <NavbarItems key={index} idx={idx} icons={icons} label={label} route={route} activeLinks={activeLinks} />
            ))
          }
        </div>
      </div>

  )
}