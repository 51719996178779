import React from "react";
import {useNavigate} from "react-router-dom";



export default function EvalFlow4({time, uploadSpeed, percentage}) {
  const navigate = useNavigate();
    return (
        <div className={`flex flex-col items-center w-full`}>
          <p className={`text-4xl font-SaM text-center mb-14 dark:text-white`}>Uploading File</p>
          <div className={`w-1/2 flex justify-center items-center mb-4 dark:text-white`}>
            <p className={`font-SaB text-base`}>Time Left:&nbsp;</p>
            <p className={`font-SaM text-base`}>{time}&nbsp;</p>
            <p className={`font-SaB text-base`}>&nbsp;|&nbsp;</p>
            <p className={`font-SaB text-base`}>Upload Speed:&nbsp;</p>
            <p className={`font-SaM text-base`}>{uploadSpeed}</p>
          </div>
          <div className={`w-7/12 bg-[#E5E5E5] rounded-full overflow-hidden mb-16`}>
            <div
                className={`h-5 rounded-full bg-greenAccent dark:bg-darkgreenAccent`}
                style={{
                  width: `${percentage}%`,
                  transition: "width 0.1s linear",
                }}></div>
          </div>
          <p className={`text-5xl font-PoM text-center mb-2 ml-1.5 dark:text-white`}>
            {percentage}
            {`%`}
          </p>
          <p className={`text-3xl font-Po text-center uppercase mb-20 dark:text-white`}>{`${percentage < 50 ? "Uploaded" : "done"}`}</p>
          <p className={`text-base font-SaM text-center mb-10 w-5/12 dark:text-white`}>{`${
              percentage < 50 ? "" : "It's safe to close the window. We will inform you when your evaluation is ready!"
          }`}</p>
          <button
                className={`bg-white dark:bg-[#2E3C41] w-fit text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg border border-[#D5D5D5]`}
              onClick={() => {
                navigate("/");
              }}>
            Return to Home Page
          </button>
        </div>
    )
}