import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar/navbar";
import ScoreGraph from "../components/insights/graph";
import caretButton from "../assets/icons/caretButtonBlack.svg";
import Answers from "../components/insights/answers";
import WeeklyStats from "../components/insights/weeklyStats";
import AccuracyAndScore from "../components/insights/accuracyAndScore";
import DangerTopics from "../components/insights/dangerTopics";
import ScoringTopics from "../components/insights/scoringTopics";
import MasteredTopics from "../components/insights/masteredTopics";
import ToggleButtonMenu from "../components/toggleButtonMenu";
import {useLocation} from "react-router-dom";
import useAxios from "../utils/useAxios";
import BACKEND_URL from "../constants";


export default function Insights(){
  const axios = useAxios();
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const response = useLocation().state?.response;
  const [bgToggle, setBgToggle] = useState(false);
  const [mode, setMode] = useState(0);
  const [data, setData] = useState([]);
  return(
      <div className={`bg-background dark:bg-darkBackground min-h-screen w-full flex select-none`}>
        <Navbar/>
        <div className={`w-[78%] pl-20  py-14 ${bgToggle ? 'bg-white dark:bg-darkBackground pr-60' : 'bg-background dark:bg-darkBackground pr-20'} relative flex flex-col`}>
          <button className={`flex w-fit p-3 rounded-full text-lg dark:text-white font-SaM items-center mb-11 dark:bg-[#2E3C41]`}>
            <img src={caretButton} alt={`caretButton`} className={`mr-2 h-8 w-8 dark:invert`}/>
            <p className={`pr-4`}>Back</p>
          </button>
          <h1 className={`text-4xl font-SaB mb-7 dark:text-white`}>Your Score: 87</h1>
          <ScoreGraph/>
          {response!==null && response!==undefined && <Answers response={response}/>}
        {/*  <div className={`inline-flex flex-col items-center w-full `}>*/}
        {/*    <div className={`w-fit rounded-xl bg-[#FFFFFF] text-base dark:bg-[#2e3c41] p-2 inline-flex justify-between font-SaB mb-14 relative`}>*/}
        {/*      <div id="slider" className={`absolute bottom-[0.275rem] border-gray-200 border dark:border-[#222222] z-0 left-1 transition-transform shadow-lg duration-300  ${mode === 1 ? ' translate-x-[98%] w-[32.0%]' : `${mode === 2 ? 'translate-x-[175%] w-[35.75%]' : 'w-[27.5%]'}`} h-[85%] bg-white dark:bg-darkBackground rounded-lg`}></div>*/}
        {/*      <button className={`${(mode === 0) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa text-center'} z-10 ml-6 mr-[4.5rem] py-2 `}*/}
        {/*          onClick={() => {*/}
        {/*            setMode(0)*/}
        {/*          }}>Overall*/}
        {/*      </button>*/}
        {/*      <button className={`${(mode === 1) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 mr-7 py-2 `}*/}
        {/*              onClick={() => {*/}
        {/*                setMode(1)*/}
        {/*              }}>Worksheets*/}
        {/*      </button>*/}
        {/*      <button className={`${(mode === 2) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 mx-5 py-2 `}*/}
        {/*              onClick={() => {*/}
        {/*                setMode(2)*/}
        {/*              }}>Assignments*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    /!*<ToggleButtonMenu/>*!/*/}
        {/*    <WeeklyStats mastered_topics={data?.mastered_topics} danger_topics={data?.danger_topics} quesions_practiced={data?.questions_practiced} scoring_topics={data?.scoring_topics}/>*/}
        {/*    <AccuracyAndScore/>*/}
        {/*    <DangerTopics/>*/}
        {/*    <ScoringTopics/>*/}
        {/*    <MasteredTopics/>*/}

        {/*  </div>*/}
        </div>
      </div>
  )
}
