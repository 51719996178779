import React, {useEffect, useRef, useState} from "react";
import CarouselImpl from "../CarouselImpl";
import "./style.css";
import learningPlanLockedChapter from "../../assets/icons/learningPlanLockedChapter.svg";
import startPracticeBannerBg from "../../assets/images/startPracticeBannerBg.png";
import ExploreChapterItems from "../homepage/Explore/exploreChapterItems";
import lockedIndicator from "../../assets/icons/lockedIndicator.svg";
import {useNavigate} from "react-router-dom";
function getColorByPercentage(color, percent){
  let R1 = parseInt(color.substring(1, 3), 16);
  let G1 = parseInt(color.substring(3, 5), 16);
  let B1 = parseInt(color.substring(5, 7), 16);
  let R2;
  let G2;
  let B2;
  if(percent>0){
    R2 = Math.round(R1 + (255 - R1) * ((100 - percent) / 100));
    G2 = Math.round(G1 + (255 - G1) * ((100 - percent) / 100));
    B2 = Math.round(B1 + (255 - B1) * ((100 - percent) / 100));
  }
  // console.log(R2, G2, B2, "R, G, B")

  R2 = (R2 < 255) ? R2 : 255;
  G2 = (G2 < 255) ? G2 : 255;
  B2 = (B2 < 255) ? B2 : 255;

  const RR = R2.toString(16).padStart(2, '0');
  const GG = G2.toString(16).padStart(2, '0');
  const BB = B2.toString(16).padStart(2, '0');
  return `#${RR}${GG}${BB}`;
}


export default function  CollapsibleCarousel({topic, id, heading, subheading}) {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    const levelCardRef = useRef(null);
    const [currentCard, setCurrentCard] = useState(0);
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [levelCardList, setLevelCardList] = useState([]);
    const navigate = useNavigate();
    const color = isDarkMode ? '#33565E' : '#60AFBB';

    // setCircleColor(newColor);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
      let useEffectLevelCardList = [];
      for (let i = 0; i < topic.length; i++) {
        // const finalCircleFillColor = getColorByPercentage(color, 50)
        useEffectLevelCardList.push(
            <div className={`relative w-full pt-10  items-center z-0`}>
              {/*<svg width="50" height="50">*/}
              {/*  <circle cx="10" cy="10" r="10" fill={percentageCompleted[i] > 0 ? finalCircleFillColor : '#ADB7B9'}/>*/}
              {/*</svg>*/}
              {/*<button*/}
              {/*    className={`w-44 h-44 bg-white dark:bg-darkBackground`}*/}
              {/*    ref={levelCardRef}*/}
              {/*    onClick={() => {*/}
              {/*      setCurrentCard(i);*/}
              {/*    }}>*/}
              {/*  <div className={`w-full relative h-full rounded-[2rem] p-5 ${*/}
              {/*      i === 0 ? "rounded-br-lg" : `${i === levels.length - 1 ? "rounded-bl-lg" : "rounded-b-lg"}`*/}
              {/*  }`}*/}
              {/*       style={{*/}
              {/*         backgroundColor: percentageCompleted[i] > 0 ? finalCircleFillColor : '#F3F4F6',*/}
              {/*         color: percentageCompleted[i] > 0 ? '#FFFFFF' : 'black',*/}
              {/*       }}*/}
              {/*  >*/}
              {/*    <p className={`font-Sa text-left text-sm mb-1.5`}>Level {i + 1}</p>*/}
              {/*    <p className={`font-SaM text-left text-base`}>{levels[i]}</p>*/}
              {/*    <img src={learningPlanLockedChapter} className={`absolute bottom-6 right-6`}*/}
              {/*         style={{display: percentageCompleted[i] > 0 ? "none" : "block"}}/>*/}
              {/*  </div>*/}
              {/*</button>*/}
              <ExploreChapterItems key={i} bgColor={isDarkMode ? '#1c2226' : 'white'}
                                   buttonBgColor={isDarkMode ? '#2E3C41' : '#F3F4F6'}
                                   nav_link={`/mcq/${topic?.[i]?.id}/subtopic_id`}
                                   mainTopic={topic?.[i]?.chapter?.name}
                                   subTopic={topic?.[i]?.topic}
                                   useLocationState={{previousURL: "learningPlan", isMockExam: false}}
              />
            </div>
        );
      }
      useEffectLevelCardList.push(
          <button disabled={
            // true
              false
          } className={`w-48 h-56 pt-10 items-center z-0 bg-white dark:bg-[#2E3C41] hover:scale-110 hover:translate-x-[5%] hover:translate-y-[5%] transition-transform`} onClick={() => {
            navigate(`/mcq/${id}/subtopic_id`, {state: {previousURL: "learningPlan", isMockExam: true}})
          }}>
            <div className={`w-full relative h-full rounded-[2rem] p-6 rounded-bl-lg`}
                 style={{
                   backgroundColor: '#F3F4F6',
                   color: 'black',
                 }}
            >
              <p className={`font-Sa text-left text-sm mb-1.5`}>Level {topic.length + 1}</p>
              <p className={`font-SaM text-left text-base`}>Mock Exam</p>
            </div>
          </button>
      )
      setLevelCardList(useEffectLevelCardList);
    }, []);


  const lineStyle = {
    "--gradient-width": `${((currentCard + 1) / 5) * 80}%`,
  };

  return (
      <div className={`rounded-2xl my-2 relative`}>
        <div className={`flex justify-between w-[95%] items-center py-9 px-10 bg-contain bg-no-repeat -mb-4 rounded-3xl`}
           style={{
             backgroundImage: `url(${startPracticeBannerBg})`,
             backgroundPositionX: '20rem',
             backgroundColor: '#38a4d0',
             zIndex: isOpen ? "100" : "0",
           }}
        >
          <div className={`flex flex-col`}>
            <p className={`font-Sa text-white text-base mb-1.5 w-8/12`}>{heading}</p>
            <p className={`font-SaM text-white text-lg mb-1.5 w-full`}>{subheading}</p>
          </div>
          <button className={`bg-white font-Sa h-11 text-lg rounded-lg px-5 py-2`} style={{
            boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.05)`,
          }} onClick={toggleOpen}>
            {isOpen ? 'Resume' : 'Continue'}
          </button>
        </div>

        <div
            className={`overflow-hidden  pb-5 flex h-fit bg-[#F9FBFB] dark:bg-[#2E3C41] rounded-b-3xl relative w-[95%]`}
            style={{
              maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0",
              transition: "max-height 0.5s ease-in-out",
              opacity: isOpen ? "1" : "0",
              visibility: isOpen ? "visible" : "hidden",
              zIndex: isOpen ? "0" : "-1",
            }}
        >
          <div ref={contentRef} className={`px-5 flex mt-2.5 h-fit pt-2 pb-5 rounded-lg bg-transparent relative w-11/12 z-0`}>
            <CarouselImpl
                visibleSlides={window.innerWidth < 768 ? 1 : 3.5}
                minwidth={"min-content"}
                buttonContainerWidth={"0rem"}
                buttonBgColor={"#F3F4F6"}
                buttonTopPosDivisor={8}
            >
              {levelCardList}
            </CarouselImpl>
          </div>
        </div>
      </div>
  );
}
