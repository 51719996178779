import React, {useRef, useState} from "react";
import PdfPreview from "./pdfPreview";
import device from "../../assets/icons/worksheet/device.svg";
import drive from "../../assets/icons/worksheet/drive.svg";
import upload from "../../assets/icons/worksheet/upload.svg";
import useDrivePicker from 'react-google-drive-picker';

export default function EvalFlow2({files, setUploading, setPercentage, hiddenFileInput}) {
  const handleClick = () => {
      hiddenFileInput.current.click();
  };

  const handleUpload = () => {
      setUploading(true);
      const interval = setInterval(() => {
          setPercentage((prevPercentage) => {
              if (prevPercentage < 100) {
                  return prevPercentage + 10;
              }
              return prevPercentage;
          });
      }, 1000);
      return () => clearInterval(interval);
  };

  const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      clientId: "xxxxxxxxxxxxxxxxx",
      developerKey: "xxxxxxxxxxxx",
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button')
        }
        console.log(data)
      },
    })
  }
  return (
      <div className={`w-full flex justify-center`}>
        <div className={`flex flex-col w-full items-center`}>
          <div className={`flex w-full flex-wrap justify-center gap-10 mb-36`}>
            {files.map((file, index) => (
                <div key={index} className={`inline-flex flex-col items-center w-fit`}>
                  {file.data.type.startsWith("image/") ? (
                      <img src={file.preview} alt="Preview" className={`w-44`}/>
                  ) : file.data.type === "application/pdf" ? (
                        <PdfPreview src={file.preview} key={index} showNumPages={false}/>
                  ) : (
                      <p className="dark:text-white">Preview not available for this file type</p>
                  )}
                </div>
            ))}
          </div>
          <p className={`text-base font-SaM text-center mb-3 dark:text-white`}>Add More</p>
          <div className={`flex gap-3 mb-14`}>
            <button
                type="button"
                onClick={handleClick}
                className={`flex justify-center items-center border rounded-full border-[#A1A1A1] px-5 py-2`}>
              <img src={device} className={`w-6 h-6 mr-2.5`} alt={"device"}/>
              <p className={`text-base font-Sa text-[#7A7A7A]`}>Device</p>
            </button>
            <button
                type="button"
                onClick={() => handleOpenPicker()}
                className={`flex justify-center items-center border rounded-full border-[#A1A1A1] px-5 py-2`}>
              <img src={drive} className={`w-6 h-6 mr-2.5`} alt={"drive"}/>
              <p className={`text-base font-Sa text-[#7A7A7A]`}>Drive</p>
            </button>
          </div>
          <button
              className={`bg-greenAccent dark:bg-darkgreenAccent flex gap-2.5 text-white items-center text-lg font-SaM px-32 py-3 rounded-lg`}
              onClick={handleUpload}>
            Upload
            <img src={upload} className={`w-5 h-5`} alt={"upload"}/>
          </button>
        </div>
      </div>
  )
}