import React from "react";
import blackSearch from "../assets/icons/blackSearch.svg";


export default function SearchBar({className, placeholder}) {
  return (
      <div className="relative bg-white rounded-full dark:bg-darkBackground w-full flex flex-grow items-stretch focus-within:z-10 font-SaM">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5 font-SaM">
          <img className="h-5 w-5 dark:invert" src={blackSearch} alt="icon"/>
        </div>
        <input
            name="search"
            className={`block w-full bg-white dark:bg-[#2E3C41] border-0 py-3 text-black font-SaM focus:outline-none focus:outline-transparent placeholder:font-SaM dark:placeholder:text-white ${className}`}
            placeholder={placeholder}
            // value={value}
            onChange={(e) => {
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     onTrigger();
            //   }
            // }}
            autoComplete="off"
        />
      </div>
  )
}