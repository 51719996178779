import React, {useEffect, useRef} from "react";
import continuePracticeBg from "../../../assets/images/continuePracticeBg.png";
import exploreButtonBg from "../../../assets/icons/exploreButtonBg.svg";
import exploreButton from "../../../assets/icons/exploreButton.svg";
import yellowSlider from "../../../assets/icons/graySlider.svg";
import {useNavigate} from "react-router-dom";

export default function PracticeChapterItems({ topic_id, topic, subtopic, key, bgColor, borderStyle, sliderImg }) {
  const spanRef1 = useRef();
  const spanRef2 = useRef();
  const yellowSliderRef = useRef();
  const handleHoverEnter = () => {
    spanRef1.current.style.opacity = "0";
    spanRef2.current.style.opacity = "100";
    // add animate-slideUpDiagonal class to yellowSliderRef 
    yellowSliderRef.current.classList.add("animate-slideUpDiagonal");
    yellowSliderRef.current.classList.remove("animate-slideDownDiagonal")
  }
  const handleHoverLeave = () => {
    spanRef1.current.style.opacity = "100";
    spanRef2.current.style.opacity = "0";
    // remove animate-slideUpDiagonal class from yellowSliderRef
    yellowSliderRef.current.classList.remove("animate-slideUpDiagonal");
    yellowSliderRef.current.classList.add("animate-slideDownDiagonal")
  }
  const navigate = useNavigate();
  console.log(topic, "topic")
    return (
        <button
            key={key}
            className={`flex flex-col relative w-52 h-52 items-center py-6 min-h-fit bg-contain bg-no-repeat rounded-3xl ${borderStyle}`}
            style={{
              backgroundImage: `url(${continuePracticeBg})`,
              backgroundColor: `${bgColor}`,
              backgroundPositionY: "2.5rem",
              backgroundPositionX: "center",
            }}
            onMouseEnter={handleHoverEnter}
            onMouseLeave={handleHoverLeave}
            onClick={()=>{
              navigate(`/topic/${topic_id}`);
              window.scrollTo({ top: 0, behavior: "instant" });
            }}
        >
          <div className={`flex flex-col w-full px-6`}>
            <p className={`font-SaL ${bgColor==='white' ? 'text-black' : 'text-white'} text-base mb-1 text-left`}>{topic}</p>
            <div className="relative text-white subtopic text-lg text-left">
              <span ref={spanRef1} className={`absolute ${bgColor==='white' ? 'text-black' : 'text-white'} inset-0 font-Sa opacity-100 hover:opacity-0 transition-opacity duration-300 text-left`}>{subtopic}</span>
              <span ref={spanRef2} className={`font-SaM ${bgColor==='white' ? 'text-black' : 'text-white'} opacity-0 hover:opacity-100 transition-opacity duration-300 text-left`}>{subtopic}</span>
            </div>
          </div>
          <div  ref={yellowSliderRef} className={`absolute bottom-3 left-8 hover:animate-slideUpDiagonal`}>
            <img src={sliderImg} alt={"yellowSlider"}/>
          </div>
        </button>
    );
}
