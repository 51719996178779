import React, {useEffect, useRef, useState} from "react";
import Navbar from "../components/Navbar/navbar";
import Header1 from "../components/Header";
import Header2 from "../components/chapterListingPage/header";
import ContinueLearning from "../components/chapterListingPage/continueLearning";
import BACKEND_URL from "../constants";
import ChapterRecommendationListing from "../components/chapterListingPage/chapterRecommendationListing";
import useAxios from "../utils/useAxios";
export default function ChapterListing() {
  const [data, setData] = useState([]);
  const axios = useAxios();
  useEffect(() => {
    axios.get(`${BACKEND_URL}/athena_student/chapters/`).then((response) => {
      setData(response.data);
    });
  }, []);
  console.log(data, "data");
  return(
      <div className={`bg-white dark:bg-darkBackground w-full flex select-none`}>
        <div className={`flex flex-col w-full`}>
          <Header1/>
          <div className={`flex w-full`}>
            <Navbar/>
            <div className={`flex flex-col p-10 pr-0 bg-white dark:bg-darkBackground w-[78%]`}>
              <Header2 chapterSuggestions={data?.chapters}/>
              <ContinueLearning chapters={data?.continue_practicing} />
              <ChapterRecommendationListing chapterRecommendationData={data?.chapters}/>
            </div>
          </div>
        </div>
      </div>
  )
}