import React from "react";
import profileHeaderBg from "../../assets/images/profileHeaderBg.png";


export default function ProfileHeader({profile}) {
  return(
      <div className={`w-full relative mb-20`}>
        <img src={profileHeaderBg} alt={`profileHeaderBg`} className={`w-full h-60 bg-contain bg-opacity-20 object-cover`}/>
        <div className={`flex items-center relative`}>
          <div className={`-mt-16 ml-16 bg-white dark:bg-black p-6 rounded-full mr-4`}>
            <img src={profile.profileImage} alt={`profile`}
                 className={`w-52 h-52 rounded-full dark:border-black mx-auto`}/>
          </div>
          <div className={`flex flex-col items-start mb-10`}>
            <p className={`text-2xl font-SaM dark:text-white mb-3`}>{profile.name}</p>
            <p className={`text-base font-Sa dark:text-white`}>{profile.designation} @ {profile.organization}</p>
          </div>
          <button className={`absolute right-20 bg-greenAccent dark:bg-darkgreenAccent rounded-lg text-white font-SaM text-lg px-9 py-2`}>
            Edit Profile
          </button>
        </div>

      </div>
  )
}