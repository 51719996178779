import React from "react";
import startPracticeBannerBg from "../../../assets/images/startPracticeBannerBg.png";


export default function StartPractice() {
  
  return(
      <div className={`flex flex-col mt-10`}>
        <p className={`font-SaM text-black dark:text-white text-2xl mb-4`}>Start Practising</p>
        <div className={`flex justify-between items-center py-9 px-10 bg-practiceColor2 bg-contain bg-no-repeat rounded-3xl`} style={{
            backgroundImage: `url(${startPracticeBannerBg})`,
            backgroundPositionX: '5rem',
          }}>
          <div className={`flex flex-col`}>
            <p className={`font-Sa text-white text-base mb-1.5`}>Functions</p>
            <p className={`font-SaM text-white text-lg mb-1.5 w-1/4`}>Quadratic Equations</p>
          </div>
          <button className={`bg-white font-Sa h-11 text-lg rounded-lg px-5 py-2`}>
            Start Learning
          </button>
        </div>
      </div>
  )
}