import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import EvalFlow1 from "./evalFlow1";
import EvalFlow2 from "./evalFlow2";
import EvalFlow3 from "./evalFlow3";
import EvalFlow4 from "./evalFlow4";
export default function WorksheetEvaluate() {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const time = "7 Second";
    const [uploadSpeed, setUploadSpeed] = useState("0.5 MB/s");
    const [percentage, setPercentage] = useState(0);
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const hiddenFileInputButton = useRef(null);

    return files.length !== 0 ? (
        uploading ? (percentage < 100 ? (<EvalFlow4 time={time} uploadSpeed={uploadSpeed} percentage={percentage} />) :
                (<EvalFlow3 files={files} setFiles={setFiles} setPercentage={setPercentage} setUploading={setUploading}/>)) :
            (<EvalFlow2 files={files} setUploading={setUploading} setPercentage={setPercentage} hiddenFileInput={hiddenFileInput} />)) :
        (<EvalFlow1 hiddenFileInput={hiddenFileInput} hiddenFileInputButton={hiddenFileInputButton} setFiles={setFiles} />);
}
