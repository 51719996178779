import React, {useState} from "react";
import Dropdown from "../dropdown";
import StatusButton from "../insights/StatusButton";

export default function OnboardingView6({setViewState}) {
  // const [chapters, setChapters] = useState(['Calculus', 'Algebra', 'Trigonometry', 'Statistics', 'Proofs', 'Geometry', 'Proofs', 'Proofs']);
  // return(
  //     <div className={`w-8/12 flex flex-col justify-evenly items-center`}>
  //       <div className={`flex flex-col w-full relative justify-between items-center mb-10`}>
  //         <p className={`text-xl font-SaM dark:text-white mb-5 text-center w-full`}>How many hours per week do you want
  //           to commit to improving your grades?</p>
  //         <div className={`w-full grid grid-cols-4 gap-4 rounded-xl dark:bg-[#2e3c41] p-4`}>
  //           {
  //             chapters.map((chapter, index) => {
  //               return (
  //                   <button key={index}
  //                           className={`bg-white dark:bg-darkBackground text-black dark:text-white text-base font-SaM w-28 h-28 rounded-lg focus:bg-[#38a4d0]`}>
  //                     {chapter}
  //                   </button>
  //               )
  //             })
  //           }
  //         </div>
  //       </div>
  //       {/*<input type="text" className={`w-10/12 rounded-full p-4 dark:bg-[#2E3C41] dark:text-white`} placeholder="Enter your name"/>*/}
  //       <div className={`flex justify-between w-10/12`}>
  //         <button
  //             className={`w-4/12 bg-white dark:bg-[#2e3c41] text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`}
  //             onClick={() => {
  //               setViewState(4);
  //             }}>
  //           Go Back
  //         </button>
  //         <button
  //             className={`w-4/12 bg-white dark:bg-darkgreenAccent text-black dark:text-white text-base font-SaM px-5 py-2 rounded-lg`}
  //             onClick={() => {
  //               setViewState(6);
  //             }}>
  //           Continue
  //         </button>
  //       </div>
  //
  //     </div>
  // )
}