import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";


export default function NavbarItems({idx, icons, label, route, activeLinks}) {
  const url = useLocation().pathname.split('/')[1].split('/')[0];
  const [selected, setSelected] = useState(false);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  useEffect(() => {
    if(activeLinks.some((link) => (url===link))) {
      setSelected(true);
    }
  }, [activeLinks, url]);
  console.log(selected);
  return (
    <Link to={route} className={`flex items-center gap-2 py-2 px-4 min-w-44 my-0.5 ${selected ? 'bg-white dark:bg-[#0f9d76] rounded-xl' : ''}`}
      style={{
        boxShadow:`${selected ? '0px 4px 20px 0px rgba(0, 0, 0, 0.05)' : ''}`,
      }}
    >
      <img src = {isDarkMode ? icons[0] : (selected ? icons[1] : icons[0])} className={`w-5 h-5 dark:invert`}/>
      <p className={`font-Sa ${selected ? 'text-greenAccent dark:text-white font-SaM' : 'text-black dark:text-white'}`}>{label}</p>
    </Link>
  );
}