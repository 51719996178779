import React, {useEffect, useState} from "react";
import blackCross from "../../assets/icons/blackCross.svg";
import bluePlus from "../../assets/icons/worksheet/bluePlus.svg";
import magicWand from "../../assets/icons/worksheet/magicWandWhite.svg";
import magicWandGrey from "../../assets/icons/evaluateWorksheet/magicWandGrey.svg";
import magicWandWhite from "../../assets/icons/worksheet/magicWandWhite.svg";
import axios from "axios";
import BACKEND_URL from "../../constants";
import CreateLearningSchedule from "../learningPlan/createLearningSchedule";
import ChapterRecommendation from "../learningPlan/chapterRecommendation";


export default function WorksheetGenerationForm(){
  const [mode1, setMode1] = useState(0);
  const [mode2, setMode2] = useState(0);
  // const generateWorksheetChapters = ['Quadratic Equations', 'Mensuration', 'Arithmetic Progression', 'Banking', 'Arithmetic Progression'];
  const [selectChapters, setSelectChapters] = useState(false);
  const [mainMode, setMainMode] = useState(true);
  const [chapters, setChapters] = useState([]);
  const [topicIds, setTopicIds] = useState([]);
  const [generateWorksheetTopics, setGenerateWorksheetTopics] = useState([]);
  useEffect(() => {
        if(selectChapters){
            axios.get(`${BACKEND_URL}/athena_student/chapters/`).then((response) => {
                setChapters(response.data.chapters);
            });
        }
     }, [selectChapters]);
  return (
      <div className={`w-full`}>
        {!selectChapters && (<div className={`flex flex-col items-center w-full`}>
          <p className={`text-4xl font-SaB mb-3 text-center dark:text-white`}>Generate a new worksheet!</p>
          <p className={`text-base font-Sa w-6/12 text-center mb-11 dark:text-white`}>We have trained this model on more
            than <span
                className={`font-SaB`}>1,00,000+</span> questions to help you generate a new worksheet tailored to your
            needs</p>
          <div
              className={`rounded-2xl p-3 bg-[#F0F0F0] dark:bg-[#2E3C41] flex items-center justify-center w-11/12 mb-10`}>
            <div
                className={`rounded-xl border border-[#DADADA] bg-white dark:bg-[#2E3C41] px-11 py-7 flex flex-col items-center justify-center w-full`}>
              <div className={`flex relative justify-center items-center mb-8`}>
                <p className={`text-base mr-2 font-SaM dark:text-white`}>Regular</p>
                <div
                    className={`w-fit rounded-full bg-[#EAEAEA] dark:bg-[#363636] text-base p-2 flex font-SaB relative`}>
                  <div
                      className={`absolute bottom-0 z-0 left-0 w-1/2 transition-transform duration-300 ${mode1 === 1 ? 'translate-x-full' : ''} h-full bg-greenAccent rounded-full`}></div>
                  <button
                      className={`z-10 px-5 py-2 w-1/2`}
                      onClick={() => {
                        setMode1(0)
                      }}></button>
                  <button
                      className={`z-10 px-5 py-2 w-1/2`}
                      onClick={() => {
                        setMode1(1)
                      }}></button>
                  <img
                      src={mode1 === 0 ? magicWandGrey : magicWandWhite}
                      className={`w-fit h-7 absolute ${mode1 === 0 ? 'opacity-50' : 'opacity-1'} bottom-0.5 right-2`}
                      alt={"toggle"}
                  />
                </div>
                {/*<div className={`z-0 left-1 w-1/2 transition-transform duration-300 ${selected === 1 ? 'translate-x-[93%]' : ''} h-[85%] bg-white rounded-lg`}></div>*/}
                <p className={`text-base ml-2 font-SaM dark:text-white`}>Personalise</p>
                {/*<img src={worksheetToggle} className={`w-full h-7`} alt={'toggle'}/>*/}
              </div>
              <div className={`flex flex-col w-full mb-3`}>
                <p className={`font-SaM text-base mb-1.5 dark:text-white`}>Chapters</p>
                <p className={`w-1/2 font-Sa text-sm mb-4 dark:text-white`}>Recommended chapters based on your
                  difficulties. Feel free to
                  delete some if you don't like.</p>
                <div className={`w-8/12 flex flex-wrap gap-2 mb-10`}>
                  {
                    generateWorksheetTopics?.map((chapter, index) => {
                      return (
                          <div key={index}
                               className={`bg-[#A4CCD2] p-2 rounded-lg px-4 flex items-center justify-center`}>
                              <p className={`font-SaM text-base `}>{chapter.name}</p>
                              <button onClick={() => {
                                  setGenerateWorksheetTopics((prev) => prev.filter((item) => item.id !== chapter.id));
                                  setTopicIds((prev) => prev.filter((item) => item !== chapter.id));
                              }}>
                                  <img src={blackCross} className={`w-6 h-6 ml-2`} alt={"cross"}/>
                              </button>
                          </div>
                      );
                  })
                  }
                  <button className={`rounded-lg border-[#60AFBB] border px-2 py-2 text-[#60AFBB]`} onClick={() => {
                    setSelectChapters(true);
                    // setMainMode(false);
                  }}>
                    <img src={bluePlus} className={`w-6 h-6`} alt={'plus'}/>
                  </button>
                </div>
              </div>
              <div className={`flex flex-col w-full`}>
                <p className={`font-SaM text-base mb-1.5 dark:text-white`}>Difficulty</p>
                <div
                    className={`w-[29%] rounded-xl bg-[#F6F7F8] dark:bg-darkBackground text-base p-1.5 flex justify-center font-SaB mb-14 relative`}>
                  <div id="slider"
                       className={`absolute bottom-1 z-0 left-1 transition-transform duration-300 w-[32%] ${mode2 === 1 ? 'w-[32.5%] translate-x-full' : `${mode2 === 2 ? 'translate-x-[204%]' : ''}`} h-[85%] bg-white dark:bg-[#2E3C41] rounded-lg`}></div>
                  <button
                      className={`${(mode2 === 0) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa text-center'} z-10 px-5 mr-1.5 py-2 `}
                      onClick={() => {
                        setMode2(0)
                      }}>Easy
                  </button>
                  <button
                      className={`${(mode2 === 1) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 px-5 mx-2 py-2 `}
                      onClick={() => {
                        setMode2(1)
                      }}>Medium
                  </button>
                  <button
                      className={`${(mode2 === 2) ? 'text-[#2A323A] dark:text-white' : 'text-[#888888] font-Sa'} z-10 px-5 py-2 `}
                      onClick={() => {
                        setMode2(2)
                      }}>Hard
                  </button>
                </div>
              </div>

            </div>
          </div>
          <button
              className={`bg-greenAccent dark:bg-[#38a4d0] text-white flex items-center justify-center font-SaM text-lg px-8 pr-6 py-3 rounded-xl mt-10`}
              onClick={() => {
                axios.get(`${BACKEND_URL}/athena_student/worksheet/`, {responseType: 'blob'})
                    .then(response => {
                      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'worksheet.pdf');
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    })
                    .catch(error => {
                      console.error('Error generating worksheet:', error);
                    });
              }}>
            <p>Generate Worksheet</p>
            <img src={magicWand} className={`w-6 h-6 ml-2`} alt={'magicWand'}/>
          </button>
        </div>)}
        {selectChapters && (
                    <div className={`flex flex-col items-center w-full pr-20`}>
                      <div className={`flex w-full justify-between items-center`}>
                        <p className={`text-4xl font-SaB text-center dark:text-white`}>Select Chapters</p>
                        <button onClick={() => {
                          setSelectChapters(false);
                          setMainMode(true);
                        }}
                            className={`bg-greenAccent text-white flex items-center justify-center font-SaM text-lg px-8 pr-6 py-3 rounded-xl`}>
                          <p className={`font-SaM`}>Done</p>
                          <img src={magicWandWhite} className={`w-6 h-6 ml-2`} alt={"magicWand"}/>
                        </button>
                      </div>
                      <div className={`flex flex-col w-full`}>
                        {chapters?.map(({chapter_id, chapter_name, topics}, index) => {
                          return (
                              <ChapterRecommendation key={index} chapter_id={chapter_id} chapter_name={chapter_name}
                                                     topics={topics} topicIds={topicIds} setTopicIds={setTopicIds} setGenerateWorksheetTopics={setGenerateWorksheetTopics}/>
                          );
                        })}
                      </div>

                    </div>
                )
            }
      </div>
  )
}