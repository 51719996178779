import React from "react";
import menuDots from "../../../assets/icons/menuDots.svg";
import timeIndicator from "../../../assets/icons/timeIndicator.svg";
import TaskItem from "./taskItem";

export default function CalenderTaskList({taskList}) {
  return(
    <div className={`flex flex-col gap-4 w-full mt-4 px-6`}>
      {
        taskList.map(({topic, duration}, index) => (
          <TaskItem topic={topic} num={index} duration={duration} borderColor={`#FA6D6D`}/>
        ))
      }
    </div>
  )
}