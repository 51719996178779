import React, {useEffect, useState} from "react";
import ChapterRecommendation from "./chapterRecommendation";

export default function ChapterRecommendationListing({chapterRecommendationData}){

  // const recommendedChapters = [
  //   {
  //     topic: "Commercial Arithmetic",
  //     subtopics: ["Shares and Dividends", "GST", "Banking"]
  //   },
  //   {
  //     topic: "Algebra",
  //     subtopics: ["Linear Equations", "Quadratic Equations", "Solving (simple) Problems (Based on Quadratic Equations", "Ration and Proportion", ]
  //   }
  // ]
  return(
      <div className={`flex flex-col w-11/12 pb-10`}>
        {
          chapterRecommendationData?.map(({chapter_id, chapter_name, topics}, index) => {
            return(
                <ChapterRecommendation key={index} chapter_id={chapter_id} chapter_name={chapter_name} topics={topics}/>
            )
          })
        }
      </div>
  )
}