import React from "react";
import dangerTopicIndicator from "../../assets/icons/insights/dangerTopicIndicator.svg";
import darkDangerTopicIndicator from "../../assets/icons/insights/darkDangerTopicIndicator.svg";
import scoringTopicIndicator from "../../assets/icons/insights/scoringTopicIndicator.svg";
import darkScoringTopicIndicator from "../../assets/icons/insights/darkScoringTopicIndicator.svg";
import masteredTopicIndicator from "../../assets/icons/insights/masteredTopicIndicator.svg";
import darkMasteredTopicIndicator from "../../assets/icons/insights/darkMasteredTopicIndicator.svg";

export default function TopicCard({
                                    type,
                                    functions,
                                    // reason,
                                    // score,
                                    // wrongKnowledge,
                                    marksGained
}) {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return (
      // <div className={`bg-white dark:bg-[#222222] rounded-2xl pb-7 shadow-md px-7 border-2 ${type==="Danger" ? 'border-[#FA6D6D]' : `${type==="Scoring" ? 'border-[#FDB153]' : 'border-[#00B383]'}`} relative`}>
      //   <div className="flex justify-center">
      //     <div className="rounded-full absolute -top-10">
      //       <img src={type==="Danger" ? (isDarkMode ? darkDangerTopicIndicator : dangerTopicIndicator) : (type==="Scoring" ? (isDarkMode ? darkScoringTopicIndicator : scoringTopicIndicator) : (isDarkMode ? darkMasteredTopicIndicator : masteredTopicIndicator))} alt="danger" className="w-40"/>
      //     </div>
      //   </div>
      //   <div className="grid grid-cols-2 gap-4 pt-4 pb-0">
      //     <div>
      //       {/*<h3 className="text-gray-500 dark:text-white font-Sa w-fit">Functions</h3>*/}
      //       <p className="text-lg dark:text-white font-SaM w-24">{functions}</p>
      //     </div>
      //     <div className="text-right inline-flex flex-col items-end">
      //       <h3 className="text-gray-500 dark:text-white">Reason</h3>
      //       <p className="text-base dark:text-white leading-5 font-SaM w-24">{reason}</p>
      //     </div>
      //   </div>
      //   <div className="flex justify-between gap-2 mt-4 border-t border-gray-400 pt-4">
      //     <div>
      //       <h3 className="text-gray-500 dark:text-white mb-2 font-Sa">Score</h3>
      //       <p className="text-xl dark:text-white font-SaB">{score}</p>
      //     </div>
      //     <div className="text-center w-fit">
      //       <h3 className="text-gray-500 dark:text-white mb-2 font-Sa w-44">Wrong by Knowledge</h3>
      //       <p className="text-xl dark:text-white font-SaB">{wrongKnowledge}</p>
      //     </div>
      //     <div className="">
      //       <h3 className="text-gray-500 dark:text-white mb-2 font-Sa">Marks Gain</h3>
      //       <p className="text-xl dark:text-white font-SaB text-center">{marksGained}</p>
      //     </div>
      //   </div>
      // </div>
      <div className={`flex rounded-3xl p-5 py-7 justify-between ${type==="Danger" ? 'dark:bg-[#2e3c41] dark:text-white' : `${type==="Scoring" ? 'dark:bg-white dark:text-[#2e3c41]' : 'dark:bg-darkgreenAccent dark:text-white'}`}`}>
        <p className={` text-lg font-SaB`}>{functions}</p>
        <p className={`${type==="Mastered" ? 'text-[#2e3c41]' : 'text-darkgreenAccent'} font-Nasa text-xl`}>{marksGained}</p>
      </div>
  )
}