import React, {useEffect, useRef, useState} from "react";
import blackCross from "../../assets/icons/blackCross.svg";
import bluePlus from "../../assets/icons/worksheet/bluePlus.svg";
import magicWand from "../../assets/icons/worksheet/magicWandWhite.svg";
import magicWandGrey from "../../assets/icons/evaluateWorksheet/magicWandGrey.svg";
import magicWandWhite from "../../assets/icons/worksheet/magicWandWhite.svg";
import DatePicker, { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import datePicker from "../../assets/icons/datePicker.svg";
import previousMonthButton from "../../assets/icons/previousMonth.svg";
import nextMonthButton from "../../assets/icons/nextMonth.svg";
import BACKEND_URL from "../../constants";
import caretButtonBlack from "../../assets/icons/caretButtonBlack.svg";
import ExploreChapterItems from "../homepage/Explore/exploreChapterItems";
import ChapterRecommendation from "../learningPlan/chapterRecommendation";
import useAxios from "../../utils/useAxios";

export default function CreateLearningSchedule({setViewMode}) {
    const [mode, setMode] = useState(0);
    const [mode1, setMode1] = useState(0);
    const [mode2, setMode2] = useState(0);
    const [text, setText] = useState("");
    const [examDate, setExamDate] = useState(null);
    // const [textareaHeight, setTextareaHeight] = useState(36); // initial height
    const [generateWorksheetTopics, setGenerateWorksheetTopics] = useState([]);
    const [calendarOpened, setCalendarOpened] = useState(false);
    const inputRef = useRef();
    const [selectChapters, setSelectChapters] = useState(false);
    const [mainMode, setMainMode] = useState(true);
    const [chapters, setChapters] = useState([]);
    const [topicIds, setTopicIds] = useState([]);
    const axios = useAxios();
    console.log(examDate, "examDate")
    const formatDate = (date) => {
        const day = date.day <= 9 ? `0${date.day}` : date.day;
        const month = date.month <= 9 ? `0${date.month}` : date.month;
        return `${day}/${month}/${date.year}`;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target)
            ) {
                setCalendarOpened(false);
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

     useEffect(() => {
        if(calendarOpened){
            const calenderButtons = document.querySelectorAll(".Calendar__monthArrowWrapper");
            const previousButton = calenderButtons[0];
            const nextButton = calenderButtons[1];
            // remove all the inner elements of the previous button
            previousButton.innerHTML = `<img src=${previousMonthButton} alt='previous month' class="rotate-90 dark:invert" />`;
            nextButton.innerHTML = `<img src=${nextMonthButton} alt='next month' class="-rotate-90 dark:invert" />`;
        }
    }, [calendarOpened]);

     useEffect(() => {
        if(selectChapters){
            axios.get(`${BACKEND_URL}/athena_student/chapters/`).then((response) => {
                setChapters(response.data.chapters);
            });
        }
     }, [selectChapters]);
    const renderCustomInput = ({ ref }) => (
        <div className={`relative w-36 dark:bg-[#2E3C41] p-2.5 pt-2 inline-block rounded-lg border border-[#F4F4F4]`}>
            <input
                className={`text-left w-full dark:bg-[#2E3C41] font-Sa text-sm placeholder:font-Sa placeholder:text-sm focus:outline-none focus:outline-transparent `}
                readOnly
                ref={(node) => {
                    if(ref) {
                        ref.current = node;
                        console.log(ref.current, "iref.current")
                    }
                    inputRef.current = node;
                }}
                placeholder="DD/MM/YYYY"
                value={examDate ? formatDate(examDate) : ""}
                onClick={() => setCalendarOpened(true)}
            />
            <img src={datePicker} onClick={() => {
                inputRef.current && inputRef.current.focus();
                setCalendarOpened(true);
            }} alt="Icon" className={`absolute top-1/2 w-5 h-5 z-0 -translate-y-1/2 right-2.5 dark:invert`} />
        </div>
    );
    console.log(topicIds, "topicIds")
    const handleSubmit = () => {
        axios.post(`${BACKEND_URL}/athena_student/learning_plan/`, {
          topic_id_list: topicIds,
          date_of_exam: formatDate(examDate)
        }).then((response) => {
          console.log(response, "response")
          setViewMode(0);
        });
    };
    return (
        <div className={`w-full`}>
            {mainMode && (
                <div className={`flex flex-col items-center w-full`}>
                    {/*<div className={`w-fit rounded-xl  bg-[#F9FAFB] dark:bg-darkBackground text-base p-2 flex gap-10 font-SaB mb-10 relative`}>*/}
                    {/*    <div*/}
                    {/*        id="slider"*/}
                    {/*        className={`absolute bottom-[0.2rem] dark:bg-[#2E3C41] z-0 left-1.5 transition-transform duration-300 ${*/}
                    {/*            mode === 1 ? "translate-x-[119%]" : ""*/}
                    {/*        } w-[44%] h-[85%] bg-white rounded-lg`}*/}
                    {/*        style={{*/}
                    {/*            boxShadow: `0px 2px 6px -2px rgba(212, 212, 212, 1),*/}
                    {/*              0px 0px 0px 1px rgba(234, 237, 240, 1),*/}
                    {/*              0px -2px 4px 0px rgba(244, 244, 244, 1) inset`,*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <button*/}
                    {/*        className={`${mode === 0 ? "text-[#2A323A] dark:text-white" : "text-[#888888] font-Sa"} z-10 px-5 py-1 `}*/}
                    {/*        onClick={() => {*/}
                    {/*            setMode(0);*/}
                    {/*        }}>*/}
                    {/*        Learning Plan*/}
                    {/*    </button>*/}
                    {/*    <button*/}
                    {/*        className={`${mode === 1 ? "text-[#2A323A] dark:text-white" : "text-[#888888] font-Sa"} z-10 pl-9 pr-10 py-1 `}*/}
                    {/*        onClick={() => {*/}
                    {/*            setMode(1);*/}
                    {/*        }}>*/}
                    {/*        Insights*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <p className={`text-4xl font-SaB mb-3 text-center dark:text-white`}>Create a learning schedule</p>
                    <p className={`text-base font-Sa w-7/12 text-center mb-11 dark:text-white`}>
                        Generate a learning plan catered to your strengths and learning style. Simply select the chapters
                        for the upcoming examination and
                        we will craft a learning plan for you!
                    </p>
                    <div
                        className={`rounded-2xl p-3 bg-[#F0F0F0] dark:bg-[#2E3C41] flex items-center justify-center w-11/12 mb-10`}>
                        <div
                            className={`rounded-xl border border-[#DADADA] bg-white dark:bg-[#2E3C41] px-11 py-7 flex flex-col items-center justify-center w-full`}>
                            <div className={`flex flex-col w-full mb-3`}>
                                <p className={`font-SaM text-base mb-1.5 dark:text-white`}>Chapters</p>
                                <p className={`w-1/2 font-Sa text-sm mb-4 dark:text-white`}>
                                    Recommended chapters based on your difficulties. Feel free to delete some if you don't
                                    like.
                                </p>
                                <div className={`w-full flex flex-wrap gap-2 mb-10`}>
                                    {generateWorksheetTopics.map((chapter, index) => {
                                        return (
                                            <div key={index}
                                                 className={`bg-[#A4CCD2] p-2 rounded-lg px-4 flex items-center justify-center`}>
                                                <p className={`font-SaM text-base `}>{chapter.name}</p>
                                                <button onClick={() => {
                                                    setGenerateWorksheetTopics((prev) => prev.filter((item) => item.id !== chapter.id));
                                                    setTopicIds((prev) => prev.filter((item) => item !== chapter.id));
                                                }}>
                                                    <img src={blackCross} className={`w-6 h-6 ml-2`} alt={"cross"}/>
                                                </button>
                                            </div>
                                        );
                                    })}
                                    <button className={`rounded-lg border-[#60AFBB] border px-2 py-2 text-[#60AFBB]`}
                                            onClick={() => {
                                                setSelectChapters(true);
                                                setMainMode(false);
                                            }}>
                                        <img src={bluePlus} className={`w-6 h-6`} alt={"plus"}/>
                                    </button>
                                </div>
                            </div>
                            {/*<div className={`flex flex-col w-full mb-5`}>*/}
                            {/*    <p className={`font-SaM text-base mb-1.5 dark:text-white`}>Personal Preferences (if any)</p>*/}
                            {/*    <p className={`w-1/2 font-Sa text-sm mb-4 dark:text-white`}>*/}
                            {/*        Want to focus on one chapter more? Let us know if you have any preferences and we will*/}
                            {/*        try to accommodate that.*/}
                            {/*    </p>*/}
                            {/*    <textarea*/}
                            {/*        id="message"*/}
                            {/*        className="font-SaM overflow-y-hidden border-[#F0F0F0] dark:bg-[#2E3C41]  dark:text-white rounded-lg px-3 py-2 border text-sm  focus:ring-transparent focus:outline-none placeholder:font-Sa placeholder:text-[#E5E5E5]"*/}
                            {/*        placeholder="I don't want to focus on Arithmetic heavily this semester."*/}
                            {/*        value={text}*/}
                            {/*        onChange={(event) => {*/}
                            {/*            setText(event.target.value);*/}
                            {/*            const {scrollHeight} = event.target;*/}
                            {/*            setTextareaHeight(scrollHeight);*/}
                            {/*        }}*/}
                            {/*        style={{height: textareaHeight, resize: "none"}}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className={`flex flex-col w-full mb-20`}>
                                <p className={`font-SaM text-base mb-2 dark:text-white`}>Exam Dates</p>
                                <div className="custom-datepicker-container">
                                    <DatePicker
                                        value={examDate}
                                        renderInput={renderCustomInput}
                                        colorPrimary={"#1B7F8E"}
                                        onChange={(date) => setExamDate(date)}
                                        inputPlaceholder={"DD/MM/YYYY"}
                                        calendarPopperPosition="auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => {
                      if(examDate && topicIds.length > 0) {
                        handleSubmit();
                      }
                    }}
                        className={`bg-greenAccent text-white flex items-center justify-center font-SaM text-lg px-8 pr-6 py-3 rounded-xl mt-10`}>
                        <p>Generate Learning Plan</p>
                        <img src={magicWand} className={`w-6 h-6 ml-2`} alt={"magicWand"}/>
                    </button>
                </div>
            )}
            {
                selectChapters && (
                    <div className={`flex flex-col items-center w-full pr-20`}>
                      <div className={`flex w-full justify-between items-center`}>
                        <p className={`text-4xl font-SaB text-center dark:text-white`}>Select Chapters</p>
                        <button onClick={() => {
                          setSelectChapters(false);
                          setMainMode(true);
                        }}
                            className={`bg-greenAccent text-white flex items-center justify-center font-SaM text-lg px-8 pr-6 py-3 rounded-xl`}>
                          <p className={`font-SaM`}>Done</p>
                          <img src={magicWandWhite} className={`w-6 h-6 ml-2`} alt={"magicWand"}/>
                        </button>
                      </div>
                      <div className={`flex flex-col w-full`}>
                        {chapters?.map(({chapter_id, chapter_name, topics}, index) => {
                          return (
                              <ChapterRecommendation key={index} chapter_id={chapter_id} chapter_name={chapter_name}
                                                     topics={topics} topicIds={topicIds} setTopicIds={setTopicIds} setGenerateWorksheetTopics={setGenerateWorksheetTopics}/>
                          );
                        })}
                      </div>

                    </div>
                )
            }
        </div>
    );
}
