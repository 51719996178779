import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BACKEND_URL from "../constants";
// import Latex from "react-latex-next";
import useAxios from "../utils/useAxios";
import 'katex/dist/katex.min.css';
import {MathJax, MathJaxContext} from "better-react-mathjax";
import Latex from "react-latex-next";


export default function Mcq(){
  const axios = useAxios();
  const { topic_id, subtopic_id } = useParams();
  const previousURL = useLocation().state?.previousURL;
  const isMock = useLocation().state?.isMock;
  const [questions, setQuestions] = useState([]);
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    if(previousURL !== undefined && topic_id !== undefined && subtopic_id !== undefined) {
      const apiUrl = (previousURL === "topic")
          ? `${BACKEND_URL}/athena_student/subtopic/question?subtopic_id=${subtopic_id}&question_type=mcq`
          : `${isMock ? `${BACKEND_URL}/athena_student/learning_plan/topic/${topic_id}?question_type=mock` : `${BACKEND_URL}/athena_student/learning_plan/topic/${topic_id}?question_type=learning`}`;
      axios.get(apiUrl).then((response) => {
        setQuestions(response.data);
      });
    }
  }, [previousURL, subtopic_id, topic_id]);

  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [optionSelected, setOptionSelected] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    if (questions.length > 0) {
      setOptionSelected(new Array(questions.length).fill(null));
    }
  }, [questions]);
  const updateAnswerList = (questionId, answer, timeSpent) => {
    setAnswerList((prev) => {
      const existingAnswerIndex = prev.findIndex((item) => item.question_id === questionId);
      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prev];
        updatedAnswers[existingAnswerIndex].answer = answer;
        updatedAnswers[existingAnswerIndex].time_spent = timeSpent;
        return updatedAnswers;
      } else {
        return [...prev, { question_id: questionId, answer, time_spent: timeSpent }];
      }
    });
  };
  const handleAnswerSubmit = () => {
  const questionType = previousURL === "learningPlan" ? "learning" : "mcq";
  const mcqApiUrl = `${BACKEND_URL}/athena_student/subtopic/question?question_type=mcq`;
  const learningApiUrl = `${isMock ? `${BACKEND_URL}/athena_student/learning_plan/topic/${topic_id}?question_type=mock` : `${BACKEND_URL}/athena_student/learning_plan/topic/${topic_id}?question_type=learning`}`;
  if(previousURL === "learningPlan"){
    axios.post(learningApiUrl, answerList).then((response) => {
      console.log(response.data);
      navigate("/insights", {
        state: {
          previousURL: "mcq",
          response: response.data,
        },
      });
    });
  }else{
    axios.post(mcqApiUrl, answerList).then((response) => {
      console.log(response.data);
      navigate("/insights", {
        state: {
          previousURL: "mcq",
          response: response.data,
        },
      });
    });
  }
}
  return (
    <MathJaxContext>
      <div className={`flex flex-col items-center bg-[#F2F2F2] dark:bg-[#2E3C41] min-h-screen`}>
        <p className={`text-black dark:text-white text-xl font-SaM mt-10`}>
          {/*{topic?.topic?.topic} | {topic?.sub_topic}*/}
          Question
        </p>
        <div id="completed-bar" className="h-4 relative mt-4 w-6/12 bg-[#e0e0e0] rounded-full">
            <div
                className={`h-full bg-darkgreenAccent dark:bg-[#38a4d0] rounded-full`}
                style={{width: `${(questionsAnswered/questions?.length)*100}%`}}
            />
        </div>
        <div className={`bg-white relative dark:bg-darkBackground rounded-3xl p-10 w-11/12 my-10 min-h-[35rem] flex flex-col`}>
          <div
              className={`rounded-3xl p-10 flex items-center justify-center w-full bg-[#F2F2F2] dark:bg-[#2E3C41] relative`}>
            <p className={`absolute right-5 top-4 text-2xl font-SaB text-[#0F9D76] dark:text-[#38a4d0]`}>?</p>
            {questions[currentQuestion] && (
                <p className={`dark:text-white text-lg`}>
                  <Latex>
                      {questions[currentQuestion]?.question}
                  </Latex>
                  {/*<MathJax>*/}
                  {/*  {questions[currentQuestion]?.question}*/}
                  {/*</MathJax>*/}
                </p>
            )}
            {/*<p className={`text-black dark:text-white w-full h-28 text-lg font-Sa`}>{questions?.[currentQuestion]?.question}</p>*/}
          </div>

          <div className={`flex flex-col w-full gap-y-5 mt-10 mb-14 dark:text-white text-lg`}>
            {
              questions?.[currentQuestion]?.answer?.map((option, index) => (
                  (option && (
                      <button className={`w-full rounded-2xl h-12 transition-colors duration-300`} style={{
                        backgroundColor: optionSelected?.[currentQuestion] === index ? `${isDarkMode ? '#38A4D0' : '#1B7F8E'}` : `${isDarkMode ? '#2e3c41' : '#F2F2F2'}`,
                      }} onClick={()=>{
                        setOptionSelected(optionSelected.map((value, i) => {
                          return i === currentQuestion ? index : value;
                        }));
                      }}>

                        <Latex macros={{ "\\frac": "\\frac"}} delimiters={[
                            { left: '$$', right: '$$', display: true },
                            { left: '\\[', right: '\\]', display: true },
                            { left: '$', right: '$', display: false },
                            { left: '\\(', right: '\\)', display: false },
                          ]}>
                          {option}
                        </Latex>
                        {/*<MathJax>*/}
                        {/*  {option}*/}
                        {/*</MathJax>*/}
                      </button>)
                  )
              ))
            }
            {console.log(optionSelected?.[currentQuestion], "optionSelected?.[currentQuestion]")}
          </div>
          <div className={`flex justify-between w-full px-56 absolute bottom-5`}>
            <button className={`bg-[#1B7F8E] dark:bg-darkgreenAccent text-white rounded-full py-3 px-12 mt-3`} onClick={()=>{
              if(currentQuestion>0){
                setCurrentQuestion(currentQuestion-1);
                setStartTime(Date.now())
              }
            }}>Back</button>
            <button className={`bg-[#0f9d76] dark:bg-darkgreenAccent text-white rounded-full py-3 px-12 mt-3`} onClick={()=>{
              // if(currentQuestion<questions?.length-1){
              //   setAnswerList([...answerList, {
              //     question_id: parseInt(questions[currentQuestion].id, 10),
              //     answer: optionSelected?.[currentQuestion]!==null ? questions?.[currentQuestion]?.answer?.[optionSelected?.[currentQuestion]] : null
              //   }]);
              //   setCurrentQuestion(currentQuestion+1)
              //   setQuestionsAnswered(optionSelected?.[currentQuestion]!==null ? questionsAnswered+1 : questionsAnswered);
              // }else{
              //   setAnswerList([...answerList, {
              //     question_id: parseInt(questions[currentQuestion].id, 10),
              //     answer: optionSelected?.[currentQuestion]!==null ? questions?.[currentQuestion]?.answer?.[optionSelected?.[currentQuestion]] : null
              //   }]);
              //   console.log(answerList);
              //   setQuestionsAnswered(optionSelected?.[currentQuestion]!==null ? questionsAnswered+1 : questionsAnswered);
              //   handleAnswerSubmit();
              // }
              const questionId = parseInt(questions[currentQuestion].id, 10);
              const answer = optionSelected?.[currentQuestion] !== null ? questions?.[currentQuestion]?.answer?.[optionSelected?.[currentQuestion]] : null;
              const timeSpent = Date.now() - startTime;
              updateAnswerList(questionId, answer, Date.now() - startTime);

              if (currentQuestion < questions?.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
                setQuestionsAnswered(optionSelected?.[currentQuestion] !== null ? questionsAnswered + 1 : questionsAnswered);
                setStartTime(Date.now());
              } else {
                setQuestionsAnswered(optionSelected?.[currentQuestion] !== null ? questionsAnswered + 1 : questionsAnswered);
                handleAnswerSubmit();
              }
            }}>{currentQuestion < questions?.length-1 ? 'Next': 'Submit'}</button>
          </div>
        </div>
      </div>
    </MathJaxContext>
  )
}