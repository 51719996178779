import React from 'react';

export default function StatusButton({ status, count }){
  let bgColor, borderColor, textColor;

  switch (status) {
    case 'Correct':
      bgColor = 'bg-[#7ED6BE] dark:bg-[#5C9183]';
      borderColor = 'border-greenAccent';
      textColor = 'text-black dark:text-white';
      break;
    case 'Incorrect':
      bgColor = 'bg-[#E9918B] dark:bg-[#E9918BB2]';
      borderColor = 'border-[#912F29]';
      textColor = 'text-black dark:text-white';
      break;
    case 'Skipped':
      bgColor = 'bg-[#E7E7E7]';
      borderColor = 'border-black';
      textColor = 'text-[#3C3C3C]';
      break;
    default:
      bgColor = 'bg-white';
      borderColor = 'border-black';
      textColor = 'text-[#3C3C3C]';
  }

  return (
    <div className={`inline-flex items-center px-5 py-3 border ${borderColor} ${bgColor} rounded-md shadow-sm`}>
      <span className={`font-bold ${textColor} mr-2`}>{count}</span>
      <span className={`${textColor}`}>{status}</span>
    </div>
  );
};


